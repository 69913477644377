/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Input, Col, Modal, Row, Skeleton, Table, Tooltip, Typography } from 'antd';
import {
    EditOutlined,
    DeleteOutlined,
    CaretUpOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { deleteAdminSpare } from '../../redux/admin/sparesAdmin/action';
import { clearConfigCache } from 'prettier';
const { Text } = Typography;

const SparesList = (props) => {
    const {

        setOpenModel,
        equipTypeList,
        sparesAdminList,
        adminEquipTypesLoading,
        spareTypeForm,
        isSparesListLoading,
        setCurrency,
        setEditSpareId,
        onGetFetchSparesAdminList,
        sparesDataCount,


        setEditModelValue,
        setEditRecord,
        onDeleteAdminSpare,
        isEquipTypeDeleteLoading,
        equipTypeSearchForm,
    } = props;

    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteEquipId, setDeleteEquipId] = useState('');
    const [current, setCurrent] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [equipmentType, setEquipmentType] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [binLocation, setBinLocation] = useState('');
    const [notes, setNotes] = useState('');
    const [preferredSupplier, setPreferredSupplier] = useState('');
    const [refurbKitQty, setRefurbKitQty] = useState(0);
    const [sparesKitQty, setSparesKitQty] = useState(0);
    const [supplierPart, setSupplierPart] = useState('');
    const [equipmentSerialNumber, setEquipmentSerialNumber] = useState('');
    const [sapSparesKitNumber, setSapSparesKitNumber] = useState('');
    const [partSapNumber, setPartSapNumber] = useState('');
    const [description, setDescription] = useState('');
    const [unit, setUnit] = useState('');
    const [safetyStock, setSafetyStock] = useState('');
    const [unitCost, setCost] = useState('');


    const typesListColumns = [
        {
            // title: 'S.No',
            dataIndex: 'serialNo',
            key: 'serialNo',
            align: 'center',
            width: '80px',
            render: (text, record, index) => (current - 1) * currentPagesize + index + 1,
        },
        {
            key: 'equipmentType',
            align: 'center',
            width: '30px',
            sorter: (a, b) => a.equipmentType.localeCompare(b.equipmentType),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Equipment Type</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setEquipmentType(value);
                                onGetFetchSparesAdminList({ equipmentType: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'equipmentType',
            key: 'equipmentType',
            align: 'left',
            ellipsis: true,
            // sorter: (a, b) => a.equipmentType.localeCompare(b.equipmentType),
        },
        {
            key: 'subCategory',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.subCategory.localeCompare(b.subCategory),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Sub Category</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSubCategory(value)
                                onGetFetchSparesAdminList({ subCategory: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'subCategory',
            key: 'subCategory',
            align: 'left',
            ellipsis: true,
            // sorter: (a, b) => a.equipmentSerialNumber.localeCompare(b.equipmentSerialNumber),
        },
        // {
        //     key: 'equipmentSerialNumber',
        //     align: 'left',
        //     width: '30px',
        //     sorter: (a, b) => a.equipmentSerialNumber.localeCompare(b.equipmentSerialNumber),
        // },
        // {
        //     title: (
        //         <div style={{ display: 'flex', flexDirection: 'column' }}>
        //             <div style={{ marginBottom: '4px' }}>Equipment SI No</div>
        //             <div className='searchDashboardSpares'>
        //                 <Input
        //                     placeholder="Search"
        //                     onChange={(e) => {
        //                         const value = e.target.value;
        //                         setEquipmentSerialNumber(value)
        //                         onGetFetchSparesAdminList({ equipmentSerialNumber: value });
        //                     }}
        //                     allowClear
        //                 />
        //             </div>
        //         </div>
        //     ),
        //     dataIndex: 'equipmentSerialNumber',
        //     key: 'equipmentSerialNumber',
        //     align: 'left',
        //     ellipsis: true,
        //     // sorter: (a, b) => a.equipmentSerialNumber.localeCompare(b.equipmentSerialNumber),
        // },
        {
            key: 'sapSparesKitNumber',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.sapSparesKitNumber.localeCompare(b.sapSparesKitNumber),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>SAP KIT No</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSapSparesKitNumber(value);
                                onGetFetchSparesAdminList({ sapSparesKitNumber: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'sapSparesKitNumber',
            key: 'sapSparesKitNumber',
            align: 'left',
            // ellipsis: true,
            // sorter: (a, b) => a.sapSparesKitNumber.localeCompare(b.sapSparesKitNumber),
        },
        {
            key: 'partSapNumber',
            align: 'left',
            width: '30px',
            sorter: (a, b) => {
                const partA = a.partSapNumber || "";
                const partB = b.partSapNumber || "";
                return partA.localeCompare(partB);
            },
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>SAP Number</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setPartSapNumber(value);
                                onGetFetchSparesAdminList({ partSapNumber: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'partSapNumber',
            key: 'partSapNumber',
            align: 'left',
            ellipsis: true,
            // sorter: (a, b) => a.partSapNumber.localeCompare(b.partSapNumber),
        },
        {
            key: 'description',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.description.localeCompare(b.description),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Description</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setDescription(value);
                                onGetFetchSparesAdminList({ description: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            // width: '15%',
            align: 'left',
            // ellipsis: true,
            // sorter: (a, b) => a.description.localeCompare(b.description),
            render: (text) => (
                <div className="description-cell">{text.length > 37 ? text.slice(0, 37) + "..." : text}</div>
            ),
        },
        // {
        //     key: 'unit',
        //     align: 'right',
        //     width: '30px',
        //     sorter: (a, b) => a.unit.localeCompare(b.unit),
        // },
        // {
        //     title: (
        //         <div style={{ display: 'flex', flexDirection: 'column' }}>
        //             <div style={{ marginBottom: '4px' }}>Unit</div>
        //             <div className='searchDashboardSpares'>
        //                 <Input
        //                     placeholder="Search"
        //                     onChange={(e) => {
        //                         const value = e.target.value;
        //                         setUnit(value)
        //                         onGetFetchSparesAdminList({ unit: value });
        //                     }}
        //                     allowClear
        //                 />
        //             </div>
        //         </div>
        //     ),
        //     dataIndex: 'unit',
        //     key: 'unit',
        //     align: 'left',
        //     width: '100px',
        //     ellipsis: true,
        //     render: (text, record) => (
        //         <div style={{ textAlign: 'center' }}>{text}</div>
        //     ),
        //     // sorter: (a, b) => a.unit.localeCompare(b.unit),
        // },
        {
            key: 'sparesKitQty',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.sparesKitQty - b.sparesKitQty,
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Spares Kit Qty</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            type="number"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSparesKitQty(value);
                                onGetFetchSparesAdminList({ sparesKitQty: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'sparesKitQty',
            key: 'sparesKitQty',
            align: 'left',
            ellipsis: true,
        },
        {
            key: 'safteyStock',
            align: 'right',
            width: '30px',
            sorter: (a, b) => String(a.safteyStock).localeCompare(String(b.safteyStock)),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Safety Stock</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSafetyStock(value);
                                onGetFetchSparesAdminList({ safteyStock: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'safteyStock',
            key: 'safteyStock',
            // width: '7%',
            // align: 'left',
            ellipsis: true,
            // sorter: (a, b) => String(a.safteyStock).localeCompare(String(b.safteyStock)),
            render: (text, record) => (
                <div style={{ textAlign: 'center' }}>{text}</div>
            ),
        },
        {
            key: 'refurbKitQty',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.refurbKitQty - b.refurbKitQty,
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Refurb Kit Qty</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            type="number"
                            onChange={(e) => {
                                const value = e.target.value;
                                setRefurbKitQty(value);
                                onGetFetchSparesAdminList({ refurbKitQty: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'refurbKitQty',
            key: 'refurbKitQty',
            align: 'left',
            ellipsis: true,
        },
        {
            key: 'unitCost',
            align: 'right',
            width: '30px',
            sorter: (a, b) => a.unitCost.localeCompare(b.unitCost),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Unit Cost</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setCost(value)
                                onGetFetchSparesAdminList({ unitCost: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'unitCost',
            key: 'unitCost',
            align: 'left',
            // width: '7%',
            ellipsis: true,
            // sorter: (a, b) => a.unitCost.localeCompare(b.unitCost),
            render: (text, record) => (
                <div className="description-cell">{record.currency == 'dollar' ? '$' : record.currency == 'pound' ? '£' : '€'}        {text}</div>
            ),
        },
        {
            key: 'binLocation',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.binLocation?.localeCompare(b.binLocation),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Bin Location</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setBinLocation(value);
                                onGetFetchSparesAdminList({ binLocation: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'binLocation',
            key: 'binLocation',
            align: 'left',
            ellipsis: true,
        },
        {
            key: 'supplierPart',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.supplierPart?.localeCompare(b.supplierPart),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Supplier Part</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setSupplierPart(value);
                                onGetFetchSparesAdminList({ supplierPart: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'supplierPart',
            key: 'supplierPart',
            align: 'left',
            ellipsis: true,
        },
        {
            key: 'preferredSupplier',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.preferredSupplier?.localeCompare(b.preferredSupplier),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Preferred Supplier</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setPreferredSupplier(value);
                                onGetFetchSparesAdminList({ preferredSupplier: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'preferredSupplier',
            key: 'preferredSupplier',
            align: 'left',
            ellipsis: true,
        },
        {
            key: 'notes',
            align: 'left',
            width: '30px',
            sorter: (a, b) => a.notes?.localeCompare(b.notes),
        },
        {
            title: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '4px' }}>Notes</div>
                    <div className='searchDashboardSpares'>
                        <Input
                            placeholder="Search"
                            onChange={(e) => {
                                const value = e.target.value;
                                setNotes(value);
                                onGetFetchSparesAdminList({ notes: value });
                            }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'notes',
            key: 'notes',
            align: 'left',
            ellipsis: true,
        },
        // {
        //     title: 'QTY',
        //     dataIndex: 'qty',
        //     key: 'qty',
        //     align: 'center',
        //     width: '5%',
        //     ellipsis: true,
        // },
        {
            title: 'Total Cost',
            dataIndex: 'totalCost',
            key: 'totalCost',
            align: 'center',
            ellipsis: true,
            render: (text, record) => {
                const unitCost = record.unitCost;
                const quantity = record.sparesKitQty;
                const totalCost = unitCost * quantity;
                const totalCostRounded = totalCost.toFixed(2);
                return (
                    <span>{record.currency == 'dollar' ? '$' : record.currency == 'pound' ? '£' : '€'} {totalCostRounded}</span>
                );

            },
        },

        {
            title: '',
            key: 'action',
            dataIndex: 'action',
            align: 'center',
            width: '100px',
            fixed:'right',
            render: (text, record) => {
                return (
                    <div className='tableIcons'>
                        <Tooltip title='Update' placement='left'>
                            <EditOutlined className='icon-18' style={{ margin: '0 10px', color: '#ef314c' }}
                                onClick={() => setEditModel(record)}
                            /></Tooltip>
                        <Tooltip title='Delete' placement='top'>  <DeleteOutlined className='icon-18' style={{ margin: '0 10px', color: '#ef314c' }}
                            onClick={() => {
                                setDeleteModal(true);
                                setDeleteEquipId(record);
                            }}
                        /></Tooltip>
                    </div>
                );
            },
            align: 'center'
        },

    ];

    /** Setting values for updating equipment types */
    const setEditModel = (record) => {
        setOpenModel(true);
        setEditRecord(record);
        setEditModelValue(true);
        spareTypeForm.setFieldsValue(record);
        setCurrency(record.currency)
        setEditSpareId(record.id)
    }

    /** Resetting values on Closing Modal */
    const handleCancel = () => {
        setDeleteModal(false);
        setDeleteEquipId('');
    }

    /** Waiting to delete equipment type and close Modal */
    useEffect(() => {
        if (!isSparesListLoading) {
            setDeleteModal(false);
            setDeleteEquipId('');
        }
    }, [isSparesListLoading]);

    return (
        <div>
            <div>
                <Row gutter={24}>
                    <Col span={24} >
                        {/* <Skeleton active loading={adminEquipTypesLoading} > */}
                        <Table
                            className='spareOrder_Pagination spares-action-column'
                            columns={typesListColumns}
                            dataSource={sparesAdminList}
                            scroll={{ x: 3615, y: '100%' }}
                            loading={isSparesListLoading}
                            // showSorterTooltip={{ target: 'sorter-icon' }}
                            pagination={sparesDataCount > 10 ? {
                                position: ['bottomRight'],
                                showSizeChanger: true,
                                total: sparesDataCount,
                                defaultPageSize: 10,
                                defaultCurrent: 1,
                                current: current,

                                onChange: async (pageNumber, pageSize) => {
                                    setCurrent(pageNumber);
                                    setCurrentPagesize(pageSize);
                                    const obj = {
                                        pageSize: pageSize,
                                        pageNumber: pageNumber,
                                        equipmentType: equipmentType,
                                        subCategory: subCategory,
                                        binLocation: binLocation,
                                        notes: notes,
                                        preferredSupplier: preferredSupplier,
                                        refurbKitQty: refurbKitQty,
                                        sparesKitQty: sparesKitQty,
                                        supplierPart: supplierPart,
                                        equipmentSerialNumber: equipmentSerialNumber,
                                        sapSparesKitNumber: sapSparesKitNumber,
                                        partSapNumber: partSapNumber,
                                        description: description,
                                        unit: unit,
                                        safetyStock: safetyStock,
                                        unitCost: unitCost
                                    };
                                    await onGetFetchSparesAdminList(obj);

                                }
                            } : false
                            }
                            onChange={(pagination, filters, sorter) => {
                                if (sorter.columnKey && sorter.order) {
                                    const sortOrder = sorter.order === 'descend' ? 'DESC' : 'ASC';
                                    const sortParam = { [sorter.columnKey]: [sorter.columnKey, sortOrder] };
                                    onGetFetchSparesAdminList(sortParam);
                                }
                            }}
                        />
                        {/* </Skeleton> */}
                    </Col>
                </Row>
            </div>
            <Modal
                width="45vw"
                className="pipeline-modal"
                title="Delete Spare"
                centered
                visible={deleteModal}
                footer={[
                    <Button key="cancel" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button key="apply" type="primary" onClick={() => onDeleteAdminSpare({ id: deleteEquipId.id })} loading={isSparesListLoading}>
                        {isSparesListLoading ? 'Deleting' : 'Confirm'}
                    </Button>
                ]}
            >
                <div>
                    <Text style={{ fontSize: '16px' }}>
                        Are you sure you want to delete <b style={{ color: "#EF314C" }}>{deleteEquipId && deleteEquipId.equipmentType + " - " + deleteEquipId.equipmentSerialNumber}</b> spare?</Text>
                </div>
            </Modal>

        </div>
    )
}

const mapStateToProps = ({ spareAdminReducer }) => {
    const isSparesListLoading = get(spareAdminReducer, 'isFetchsparesAdminDataLoading', []);
    const sparesDataCount = get(spareAdminReducer, 'sparesDataCount', []);
    return {
        isSparesListLoading,
        sparesDataCount
    }
}

const mapDispatchToProps = {
    onDeleteAdminSpare: deleteAdminSpare,

}

export default connect(mapStateToProps, mapDispatchToProps)(SparesList);