import {
    put, call, all, takeLatest,
} from 'redux-saga/effects';
import axiosConfig from '../../services/axiosConfig';
import {
    fetchSparesDashBoardListSuccess, fetchSparesDashBoardListFailure, fetchsparesEuipTypeListSuccess, fetchsparesEuipTypeListFailure, fetchsparesEuipSNListSuccess, fetchsparesEuipSNListFailure, fetchSapKitNoListListSuccess, fetchSapKitNoListListFailure, fetchSapNoListListSuccess, fetchSapNoListListFailure, addSpareKitFailure, fetchEuipSNListSuccess, fetchEuipSNListFailure, fetchExcelSparesDashBoardListSuccess, fetchExcelSparesDashBoardListFailure
} from './action';
import {
    FETCH_SPARES_LIST, UPDATE_DASHBOARD_SPARE, DELETE_DASHBOARD_SPARE, FETCH_SPARE_EQUIPTYPE_LIST, FETCH_SPARE_EQUIPSN_LIST, FETCH_SPARE_SAPKITN_LIST, ADD_SPARE_KIT, FETCH_SPARE_SAPNO_LIST, ADD_SPARE_INDV, PUBLISH_DASHBOARD_LIST, FETCH_EQUIPSN_LIST, FETCH_EXCEL_SPARES_LIST
} from './types';
import get from 'lodash/get';
import { notification } from 'antd';
import axios from 'axios';


/** Get all spares Admin data Data */
function* fetchSparesDashBoardList({ projectId, pageNumber, pageSize, equipmentType, assignedToSerialNumber, equipmentSerialNumber, sapKitNo, sapNumber, description, unit, safteyStock, cost }) {
    try {
        let url;
        if (equipmentType) {
            if (Array.isArray(equipmentType)) {
                const sortParam = JSON.stringify(equipmentType);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&equipmentType=${equipmentType}`;
            }
        } else if (assignedToSerialNumber) {
            if (Array.isArray(assignedToSerialNumber)) {
                const sortParam = JSON.stringify(assignedToSerialNumber);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&assignedToSerialNumber=${assignedToSerialNumber}`;
            }
        } else if (equipmentSerialNumber) {
            if (Array.isArray(equipmentSerialNumber)) {
                const sortParam = JSON.stringify(equipmentSerialNumber);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&equipmentSerialNumber=${equipmentSerialNumber}`;
            }
        } else if (sapKitNo) {
            if (Array.isArray(sapKitNo)) {
                const sortParam = JSON.stringify(sapKitNo);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&sapKitNo=${sapKitNo}`;
            }
        } else if (sapNumber) {
            if (Array.isArray(sapNumber)) {
                const sortParam = JSON.stringify(sapNumber);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&sapNumber=${sapNumber}`;
            }
        } else if (description) {
            if (Array.isArray(description)) {
                const sortParam = JSON.stringify(description);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&description=${description}`;
            }
        } else if (unit) {
            if (Array.isArray(unit)) {
                const sortParam = JSON.stringify(unit);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&unit=${unit}`;
            }
        } else if (safteyStock) {
            if (Array.isArray(safteyStock)) {
                const sortParam = JSON.stringify(safteyStock);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&safteyStock=${safteyStock}`;
            }
        } else if (cost) {
            if (Array.isArray(cost)) {
                const sortParam = JSON.stringify(cost);
                url = `/project-spare-parts?projectId=${projectId}&sort=${encodeURIComponent(sortParam)}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}&cost=${cost}`;
            }
        } else {
            if (pageNumber && pageSize) {
                sessionStorage.setItem('pageNumberSpare', pageNumber);
                sessionStorage.setItem('pageSizeSpare', pageSize);
                url = `/project-spare-parts?projectId=${projectId}&pageSize=${pageSize}&pageNumber=${pageNumber}`;
            } else {
                url = `/project-spare-parts?projectId=${projectId}`;
            }
        }
        const res = yield axiosConfig.get(url)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchSparesDashBoardListSuccess({ response }));
        } else {
            yield put(fetchSparesDashBoardListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchSparesDashBoardListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching spares master list.',
            description: `${error.error}`,
        });
    }
}

/** Get all Excel spares Admin data Data */
function* fetchExcelSparesDashBoardList({ projectId }) {
    try {
        let url = `/project-spare-parts?projectId=${projectId}`;
        const res = yield axiosConfig.get(url)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchExcelSparesDashBoardListSuccess({ response }));
        } else {
            yield put(fetchExcelSparesDashBoardListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchExcelSparesDashBoardListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching spares master list.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares euipment Type list */
function* fetchsparesEuipTypeList() {
    try {
        const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=equipmentType`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchsparesEuipTypeListSuccess({ response }));
        } else {
            yield put(fetchsparesEuipTypeListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchsparesEuipTypeListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching equipment List.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares euipment serial Number list */
function* fetchsparesEuipSNList({ pihEquipmentType }) {
    try {
        const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=equipmentSerialNumber&equipmentType=${pihEquipmentType}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchsparesEuipSNListSuccess({ response }));
        } else {
            yield put(fetchsparesEuipSNListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchsparesEuipSNListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching equipment serial No.',
            description: `${error.error}`,
        });
    }
}

/** Get all euipment serial Number list for assign to field */
function* fetchEuipSNList({ projectId }) {
    try {
        const res = yield axiosConfig.get(`/pih-equipment/serial-numbers?projectId=${projectId}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchEuipSNListSuccess({ response }));
        } else {
            yield put(fetchsparesEuipSNListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchEuipSNListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching equipment serial No.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares euipment sap kit Number list */
function* fetchSapKitNoList({ sapKitNo, pihEquipmentType, pihEquipmentSn }) {
    try {
        const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=${sapKitNo}&equipmentType=${pihEquipmentType}&equipmentSerialNumber=${pihEquipmentSn}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchSapKitNoListListSuccess({ response }));
        } else {
            yield put(fetchSapKitNoListListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchSapKitNoListListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching Sap kit No.',
            description: `${error.error}`,
        });
    }
}

/** Get all spares euipment sap Number list */
function* fetchSapNoList({ sapNumber, pihEquipmentType, pihEquipmentSn, pihEquipmentSapKitNo }) {
    try {
        const res = yield axiosConfig.get(`/spare-part-master/column-distinct-values?requiredAttributes=${sapNumber}&equipmentType=${pihEquipmentType}&equipmentSerialNumber=${pihEquipmentSn}&sapKitNo=${pihEquipmentSapKitNo}`)
        const response = get(res, ['data', 'data'], {});
        if (res.status === 200) {
            yield put(fetchSapNoListListSuccess({ response }));
        } else {
            yield put(fetchSapNoListListFailure());
            notification.error({
                message: 'Something went wrong. Please try again.',
            });
        }
    } catch (error) {
        yield put(fetchSapNoListListFailure());
        notification.destroy();
        notification.error({
            message: 'A problem occured while fetching Sap Number.',
            description: `${error.error}`,
        });
    }
}

// Add Individual Spare 
function* addIndvSpare({ payload, selectedProject }) {
    try {
        const res = yield axiosConfig.post(`/project-spare-part/assign-individual-spare`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchSparesDashBoardList({ projectId: selectedProject.id })
            yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
            notification.success({
                message: `Data Added Successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield fetchSparesDashBoardList({ projectId: selectedProject.id })
        yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the spare kit.',
            description: `${error.error}`,
        });
    }
}

// Add Spare Kit
function* addSpareKit({ payload, selectedProject }) {
    try {
        const res = yield axiosConfig.post(`/project-spare-part/assign-spare-kit`, payload);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchSparesDashBoardList({ projectId: selectedProject.id })
            yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
            notification.success({
                message: `Data Added Successfully.`,
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        yield fetchSparesDashBoardList({ projectId: selectedProject.id })
        yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
        notification.destroy();
        notification.error({
            message: 'A problem occured while adding the spare kit.',
            description: `${error.error}`,
        });
    }
}

/** Function to update Dashboard Spare data list */
function* updateDashboardSpare({ data, selectedProject }) {
    try {
        const res = yield axiosConfig.put(`/project-spare-part`, data);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchSparesDashBoardList({ projectId: selectedProject.id })
            yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
            notification.success({
                message: 'Spare Updated Successfully.',
            });
        } else {
            yield fetchSparesDashBoardList({ projectId: selectedProject.id })
            yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchSparesDashBoardList({ projectId: selectedProject.id })
        yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
        notification.destroy();
        notification.error({
            message: 'Spare data Updation Failed.',
            description: `${error.error}`,
        });
    }
}

/** Function to publish Dashboard Spare data list */
function* publishDashboardList({ selectedProject }) {
    try {
        const res = yield axiosConfig.put(`/project-spare-parts/publish`, { projectId: selectedProject.id });
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchSparesDashBoardList({ projectId: selectedProject.id })
            yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
            notification.success({
                message: 'Spare List Published Successfully.',
            });
        } else {
            yield fetchSparesDashBoardList({ projectId: selectedProject.id })
            yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
            notification.error({
                message: 'Something went wrong. Please try again later',
            });
        }
    } catch (error) {
        yield fetchSparesDashBoardList({ projectId: selectedProject.id })
        yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
        notification.destroy();
        notification.error({
            message: 'Spare data Updation Failed.',
            description: `${error.error}`,
        });
    }
}

/** Function to delete Dashboard spare data list */
function* deleteDashboardSpare({ id, selectedProject }) {
    try {
        const res = yield axiosConfig.delete(`/project-spare-part/${id}`);
        const response = get(res, ['data', 'data'], {});
        notification.destroy();
        if (res.status === 200) {
            yield fetchSparesDashBoardList({ projectId: selectedProject.id })
            yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
            notification.success({
                message: 'Data Deleted Successfully.',
            });
        } else {
            notification.error({
                message: 'Something went wrong.',
            });
        }
    } catch (error) {
        notification.destroy();
        yield fetchSparesDashBoardList({ projectId: selectedProject.id })
        yield fetchExcelSparesDashBoardList({ projectId: selectedProject.id })
        notification.error({
            message: 'Data Deletion Failed.',
            description: `${error.error}`,
        });
    }
}




function* SpareDashBoardWatcher() {
    yield takeLatest(FETCH_SPARES_LIST, fetchSparesDashBoardList);
    yield takeLatest(FETCH_EXCEL_SPARES_LIST, fetchExcelSparesDashBoardList);
    yield takeLatest(FETCH_SPARE_EQUIPTYPE_LIST, fetchsparesEuipTypeList);
    yield takeLatest(FETCH_SPARE_EQUIPSN_LIST, fetchsparesEuipSNList);
    yield takeLatest(FETCH_EQUIPSN_LIST, fetchEuipSNList);
    yield takeLatest(FETCH_SPARE_SAPKITN_LIST, fetchSapKitNoList);
    yield takeLatest(ADD_SPARE_INDV, addIndvSpare);
    yield takeLatest(ADD_SPARE_KIT, addSpareKit);
    yield takeLatest(FETCH_SPARE_SAPNO_LIST, fetchSapNoList);
    yield takeLatest(UPDATE_DASHBOARD_SPARE, updateDashboardSpare);
    yield takeLatest(PUBLISH_DASHBOARD_LIST, publishDashboardList);
    yield takeLatest(DELETE_DASHBOARD_SPARE, deleteDashboardSpare);
}

function* spareDashBoardSagas() {
    yield all([
        call(SpareDashBoardWatcher),
    ]);
}

export default spareDashBoardSagas;