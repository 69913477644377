import {
  put, call, all, takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import get from 'lodash/get';
import {
  fetchAdminEquipSnoSuccess,
  fetchAdminEquipSnoFailure,
  addAdminEquipSnoSuccess,
  addAdminEquipSnoFailure,
  updateSerialNoSuccess,
  updateSerialNoFailure,
  deleteSerialNoSuccess,
  deleteSerialNoFailure,
  fetchAdminEquipTypeSnoSuccess,
  fetchAdminEquipTypeSnoFailure,
} from './action';
import {
  ADD_EQUIPMENT_SNO,
  DELETE_EQUIPMENT_SNO,
  FETCH_ADMIN_EQUIPMENT_SNO,
  FETCH_ADMIN_EQUIPMENT_TYPE_SNO,
  UPDATE_EQUIPMENT_SNO
} from './types';
import adminAxiosConfig from '../../../services/adminAxiosConfig';


/** Get all listed equipment types*/
function* fetchAdminEquipSno(payload) {
  try {
    var res;
    if (payload.pihEquipmentTypeCategory && payload.pihEquipmentType) {
      res = yield adminAxiosConfig.get(`/equipments?pihEquipmentTypeCategory=${encodeURIComponent(payload.pihEquipmentTypeCategory)}&pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`);
    } else {
      res = yield adminAxiosConfig.get(`/equipments?equipmentTypeId=${payload.id}`);
    }

    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipSnoSuccess({ response }));
    } else {
      yield put(fetchAdminEquipSnoFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipSnoFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipments.',
      description: `${error.error}`,
    });
  }
}

/** Get all listed equipment types for admin page (NEWLY ADDED API)*/
function* fetchAdminEquipTypeSno(payload) {
  try {
    var res;
    if (payload.searchKey && payload.pihEquipmentType) {
      res = yield adminAxiosConfig.get(`/equipments-for-pih-equipment-type?searchKey=${encodeURIComponent(payload.searchKey)}&pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`);
    } else {
      res = yield adminAxiosConfig.get(`/equipments-for-pih-equipment-type?pihEquipmentType=${encodeURIComponent(payload.pihEquipmentType)}`);
    }

    const response = get(res, ['data', 'data'], {});
    if (res.status === 200) {
      yield put(fetchAdminEquipTypeSnoSuccess({ response }));
    } else {
      yield put(fetchAdminEquipTypeSnoFailure());
      notification.warn({
        message: 'Something went wrong. Please Reload and try again',
      });
    }
  } catch (error) {
    yield put(fetchAdminEquipTypeSnoFailure());
    notification.destroy();
    notification.error({
      message: 'A problem occured while fetching the Equipments.',
      description: `${error.error}`,
    });
  }
}

/** Function to Add new Equipments (Serial Number) */
function* addAdminEquipSno({ payload }) {
  try {
    // const res = yield adminAxiosConfig.post(`/equipment`, payload);
    const res = yield adminAxiosConfig.post(`/equipments-for-pih-equipment-type`, payload);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(addAdminEquipSnoSuccess({ response }));
      // yield fetchAdminEquipSno({ id: payload.equipment.equipmentTypeId });
      yield fetchAdminEquipTypeSno({ pihEquipmentType: payload.equipment.pihEquipmentType });
      notification.success({
        message: 'Equipment Added Successfully.',
      });
    } else {
      yield put(addAdminEquipSnoFailure());
      notification.error({
        message: 'Something went wrong.Please try again!',
      });
    }
  } catch (error) {
    yield put(addAdminEquipSnoFailure());
    notification.destroy();
    if (error.error === "Validation error") {
      notification.warn({
        message: 'Selected Equipment already exist!'
      });
    } else {
      notification.error({
        message: 'A problem occured while adding the details.',
        description: `${error}`,
      });
    }
  }
}

/** Function to update Equipment */
function* updateSerialNo({ data, id }) {
  try {
    const res = yield adminAxiosConfig.put(`/equipment/${id}`, data);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(updateSerialNoSuccess({ response }));
      // yield fetchAdminEquipSno({ id: data.equipment.equipmentTypeId });
      yield fetchAdminEquipTypeSno({ pihEquipmentType: data.equipment.pihEquipmentType });
      notification.success({
        message: 'Equipment Updated Successfully.',
      });
    } else {
      yield put(updateSerialNoFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(updateSerialNoFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Type Updation Failed.',
      description: `${error}`,
    });
  }
}

/** Function to delete Equipment Types */
function* deleteSerialNo({ id, equipmentId, pihEquipmentType }) {
  try {
    const res = yield adminAxiosConfig.delete(`/equipment/${id}`);
    const response = get(res, ['data', 'data'], {});
    notification.destroy();
    if (res.status === 200) {
      yield put(deleteSerialNoSuccess({ response }));
      // yield fetchAdminEquipSno({ id: equipmentId });
      yield fetchAdminEquipTypeSno({ pihEquipmentType: pihEquipmentType });
      notification.success({
        message: 'Equipment Deleted Successfully.',
      });
    } else {
      yield put(deleteSerialNoFailure());
      notification.error({
        message: 'Something went wrong. Please try again later',
      });
    }
  } catch (error) {
    yield put(deleteSerialNoFailure());
    notification.destroy();
    notification.error({
      message: 'Equipment Deletion Failed.',
      description: `${error}`,
    });
  }
}


function* sowWatcher() {
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_SNO, fetchAdminEquipSno);;
  yield takeLatest(FETCH_ADMIN_EQUIPMENT_TYPE_SNO, fetchAdminEquipTypeSno);;
  yield takeLatest(ADD_EQUIPMENT_SNO, addAdminEquipSno);
  yield takeLatest(UPDATE_EQUIPMENT_SNO, updateSerialNo);
  yield takeLatest(DELETE_EQUIPMENT_SNO, deleteSerialNo);
}

function* adminEquipSnoSagas() {
  yield all([
    call(sowWatcher),
  ]);
}

export default adminEquipSnoSagas;