/* eslint-disable linebreak-style */
import {
  ADD_EQUIPMENT_SNO,
  ADD_EQUIPMENT_SNO_FAILURE,
  ADD_EQUIPMENT_SNO_SUCCESS,
  DELETE_EQUIPMENT_SNO,
  DELETE_EQUIPMENT_SNO_FAILURE,
  DELETE_EQUIPMENT_SNO_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_SNO,
  FETCH_ADMIN_EQUIPMENT_SNO_FAILURE,
  FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS,
  FETCH_ADMIN_EQUIPMENT_TYPE_SNO,
  FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE,
  FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS,
  UPDATE_EQUIPMENT_SNO,
  UPDATE_EQUIPMENT_SNO_FAILURE,
  UPDATE_EQUIPMENT_SNO_SUCCESS,
} from './types';

const fetchAdminEquipSno = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_SNO, ...payload
});

const fetchAdminEquipSnoSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS, ...payload,
});

const fetchAdminEquipSnoFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_SNO_FAILURE,
});

const fetchAdminEquipTypeSno = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_TYPE_SNO, ...payload
});

const fetchAdminEquipTypeSnoSuccess = (payload) => ({
  type: FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS, ...payload,
});

const fetchAdminEquipTypeSnoFailure = () => ({
  type: FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE,
});

const addAdminEquipSno = (payload) => ({
  type: ADD_EQUIPMENT_SNO,
  payload
});

const addAdminEquipSnoSuccess = (payload) => ({
  type: ADD_EQUIPMENT_SNO_SUCCESS,
  ...payload
});

const addAdminEquipSnoFailure = () => ({
  type: ADD_EQUIPMENT_SNO_FAILURE
});

const updateSerialNo = (payload) => ({
  type: UPDATE_EQUIPMENT_SNO, ...payload,
});

const updateSerialNoSuccess = (payload) => ({
  type: UPDATE_EQUIPMENT_SNO_SUCCESS, ...payload,
});

const updateSerialNoFailure = (payload) => ({
  type: UPDATE_EQUIPMENT_SNO_FAILURE, ...payload,
});

const deleteSerialNo = (payload) => ({
  type: DELETE_EQUIPMENT_SNO, ...payload,
});

const deleteSerialNoSuccess = (payload) => ({
  type: DELETE_EQUIPMENT_SNO_SUCCESS, ...payload,
});

const deleteSerialNoFailure = (payload) => ({
  type: DELETE_EQUIPMENT_SNO_FAILURE, ...payload,
});

export {
  fetchAdminEquipSno,
  fetchAdminEquipSnoSuccess,
  fetchAdminEquipSnoFailure,
  addAdminEquipSno,
  addAdminEquipSnoFailure,
  addAdminEquipSnoSuccess,
  updateSerialNo,
  updateSerialNoFailure,
  updateSerialNoSuccess,
  deleteSerialNo,
  deleteSerialNoFailure,
  deleteSerialNoSuccess,
  fetchAdminEquipTypeSno,
  fetchAdminEquipTypeSnoFailure,
  fetchAdminEquipTypeSnoSuccess
}