/* eslint-disable react/prop-types */
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined, ExportOutlined, EyeOutlined, FilePdfOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Modal, Space, Table, Tooltip, Typography, Select, notification, Tag, Row, Col, Drawer } from 'antd';
import { get, set } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { ToolbarSlot, TransformToolbarSlot, toolbarPlugin } from '@react-pdf-viewer/toolbar';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ExcelJS from 'exceljs';
import moment from 'moment';
import history from '../../services/history';
import ProjectDetails from './ProjectDetails';
import TechnicalReportPage from './TechnicalReportPage';

import PDFViewer from './PDFViewer';
import { fetchProjectList } from '../../redux/CRCETechnical/action';
import { getCategoryListFromDatabse, getExportDataListFromDatabse } from '../../redux/CRCETechnical/saga';
import { object } from 'prop-types';
import UpdateReport from './UpdateReport';


const { Option } = Select;
const { Title, Text } = Typography;


const TechnicalPage = (props) => {


    const {
        projectList,
        isLoading,
        listCount,
        onProjectList,
        listTotalCount
    } = props
    const data = [
        {
            key: '1',
            MaterialName: '3M Scotchkote 226N FBE_Borealis EP5 10/60M CMPP_Borealis EP5 10/60 PP Tape',
            BatchNumber: 'IN02 5E14_PL101HX01_NH1341E01',
            TestedDate: '2024-01-15',
            ReportedDate: '2024-02-20',
            // Add other properties as needed
        },
        {
            key: '1',
            MaterialName: '3M Scotchkote 226N ',
            BatchNumber: 'IN02 / 4L10 (804)',
            TestedDate: '2024-01-15',
            ReportedDate: '2024-02-20',
            // Add other properties as needed
        },
        // Add more objects as needed
    ];

    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [addNewForm] = Form.useForm()
    const [showTestPage, setshowTestPage] = useState(false)
    const [selectedRow, setSelectedRow] = useState('')
    const [isEdit, setIsEdit] = useState(false);
    const [editRecord, seteditRecord] = useState('');
    const [current, setCurrent] = useState(1);
    const [currentPagesize, setCurrentPagesize] = useState(10);
    const [prevPageNumber, setPrevPageNumber] = useState(1);
    const [prevPagesize, setPrevPagesize] = useState(10);
    const [selectedTestRow, setSelectedTestRow] = useState('')
    const [selectedProject, setSelectedProject] = useState('')
    const [showPDFViewer, setShowPDFViewer] = useState(false);
    //const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const [resultModalVisible, setResultModalVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [categoryType, setCategoryType] = useState([]);
    const [materialType, setMaterialType] = useState([]);
    const [selectedMaterial, setSelectedMaterial] = useState([]);
    const [temperatureType, setTemperatureType] = useState([]);
    const [fileNameType, setFileNameType] = useState([]);
    const [selectedTemperature, setSelectedTemperature] = useState([]);
    const [selectedFileName, setSelectedFileName] = useState([]);
    const [timelineType, setTimelineType] = useState([]);
    const [selectedTimeline, setSelectedTimeline] = useState([]);
    const [projectNameType, setProjectNameType] = useState([]);
    const [selectedProjectName, setSelectedProjectName] = useState([]);
    const [matrialSearchType, setMatrialSearchType] = useState('')
    const [testTypeSearchType, setTestTypeSearchType] = useState('')
    const [projectNameSearchType, setProjectNameSearchType] = useState('')
    const [timelineSearchType, setTimelineSearchType] = useState('')
    const [temperatureSearchType, setTemperatureSearchType] = useState('')
    const [fileNameSearchType, setFileNameSearchType] = useState('')
    const [searchType, setSearchType] = useState({});
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    // Create a new instance of the default layout plugin
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar: (Toolbar) => (
            <Toolbar>
                {renderDefaultToolbar(transform)}
            </Toolbar>
        ),
    });

    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        //  Download: () => <></>,

    });
    useEffect(() => {
        // const object = {
        //     header: 'Material',
        //     searchkey: searchType
        // }
        // (async () => {
        //     setMaterialType(await getCategoryList('Material'));
        //     setCategoryType(await getCategoryList('TestType'));
        //     setProjectNameType(await getCategoryList('ProjectName'));
        //     setTimelineType(await getCategoryList('Timeline'));
        //     setTemperatureType(await getCategoryList('Temperature'));
        // })();

    }, []);
    // const [showPDFViewer, setShowPDFViewer] = useState(false);
    const [searchCriteria, setSearchCriteria] = useState({
    });
    const [searchFilterCriteria, setSearchFilterCriteria] = useState({
    });
    const columns_feild = [
        {
            title: 'Project Name',
            dataIndex: 'ProjectName',
            key: 'ProjectName',
            width: '300px',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'left',
        },

        {
            title: 'Project Number',
            dataIndex: 'ProjectNumber',
            key: 'projectNumber',

            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },
        {
            title: 'Created By',
            dataIndex: 'CreatedBy',
            key: 'CreatedBy',
            align: 'center',
            // width: '150px',
            //sorter: (a, b) =>  (a.License_File_Name.localeCompare(b.License_File_Name)),
            render: (text) => <a>{text === 'undefined' ? '-' : text}</a>,
        },


        {
            title: 'Created Date',
            dataIndex: 'CreationDate',
            key: 'CreationDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        },
        {
            title: 'Updated Date',
            dataIndex: 'UpdatedDate',
            key: 'UpdatedDate',
            align: 'center',
            render: (text) => {
                return (
                    <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
                );
            },
            // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
            //sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        },

        {
            title: 'Report',
            dataIndex: 'report',
            key: 'report',
            align: 'center',
            render: (text, record) => {
                return (
                    <>
                        <Tooltip title="Download  file" >

                            <Button href={{}} className='p-0 border-0' size='small' target='_blank'>
                                <FilePdfOutlined className='pdfIconRed' />
                            </Button>

                        </Tooltip>
                    </>
                )
            }
        },
        {
            title: 'Edit',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'left',
            render: (text, record) => {
                return (

                    <div>

                        <Tooltip title="View Project Report" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onReadParts(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                //onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip>
                    </div>)
            },

        },

    ];

    const columns_feild1 = [
        {
            title: 'Material',
            dataIndex: 'Material',
            key: 'Material',
            width: '33%',
            // fixed: 'left',
            //width: '150px',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            // ellipsis: true,
            // render: (text) => (
            //     <div className="description-cell">{text.length > 60 ? text.slice(0, 60) + "..." : text}</div>
            // ),
            align: 'left',
        },

        // {
        //     title: 'Material 1',
        //     dataIndex: 'Material_1',
        //     key: 'Material_1',
        //     width: '10%',
        //     //  width: '200px',
        //     //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
        //     // render: (text) => <a>{text === ' ' ? '-' : text}</a>,
        //     render: (text) => (
        //         <div className="description-cell">{text.length > 30 ? text.slice(0, 10) + "..." : text}</div>
        //     ),
        //     align: 'left',
        // },

        // {
        //     title: 'Material 2',
        //     dataIndex: 'Material_2',
        //     key: 'Material_2',
        //     width: '10%',
        //     // width: '200px',
        //     //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
        //     //render: (text) => <a>{text === ' ' ? '-' : text}</a>
        //     render: (text) => (
        //         <div className="description-cell">{text.length > 30 ? text.slice(0, 10) + "..." : text}</div>
        //     ),
        //     align: 'left',
        // },

        // {
        //     title: 'Material 3',
        //     dataIndex: 'Material_3',
        //     key: 'Material_3',
        //     width: '10%',
        //     //width: '200px',
        //     //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
        //     // render: (text) => <a>{text === ' ' ? '-' : text}</a>,
        //     render: (text) => (
        //         <div className="description-cell">{text.length > 30 ? text.slice(0, 10) + "..." : text}</div>
        //     ),
        //     align: 'left',
        // },
        // {
        //     title: 'Project Name',
        //     dataIndex: 'ProjectName',
        //     key: 'ProjectName',
        //     width: '10%',
        //     sorter: (a, b) => (() => onClickSorting(a.ProjectName)),
        //     // width: '100px',
        //     //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
        //     //render: (text) => <a>{text === ' ' ? '-' : text}</a>,
        //     // ellipsis: true,
        //     render: (text) => (
        //         <div className="description-cell">{text.length > 30 ? text.slice(0, 10) + "..." : text}</div>
        //     ),
        //     align: 'left',
        // },
        {
            title: 'Test Type',
            dataIndex: 'TestType',
            key: 'TestType',
            width: '16%',
            // sorter: (a, b) => (a.TestType.localeCompare(b.TestType)),
            // width: '100px',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            // ellipsis: true,
            // render: (text) => (
            //     <div className="description-cell">{text.length > 30 ? text.slice(0, 60) + "..." : text}</div>
            // ),
            align: 'left',
        },
        {
            title: 'Project Name',
            dataIndex: 'ProjectName',
            key: 'ProjectName',

            width: '12%',
            // width: '100px',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            // ellipsis: true,
            // render: (text) => (
            //     <div className="description-cell">{text.length > 30 ? text.slice(0, 10) + "..." : text}</div>
            // ),
            align: 'left',
        },

        // {
        //     title: 'Batch 1',
        //     dataIndex: 'Batch_1',
        //     key: 'Batch_1',

        //     //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
        //     render: (text) => <a>{text === ' ' ? '-' : text}</a>,
        //     align: 'left',
        // },
        // {
        //     title: 'Batch 2',
        //     dataIndex: 'Batch_2',
        //     key: 'Batch_2',

        //     //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
        //     render: (text) => <a>{text === ' ' ? '-' : text}</a>,
        //     align: 'left',
        // },
        // {
        //     title: 'Batch 3',
        //     dataIndex: 'Batch_3',
        //     key: 'Batch_3',

        //     //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
        //     render: (text) => <a>{text === ' ' ? '-' : text}</a>,
        //     align: 'left',
        // },
        {
            title: 'Duration',
            dataIndex: 'Timeline',
            key: 'Timeline',
            width: '8%',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },
        {
            title: 'Temperature',
            dataIndex: 'Temperature',
            key: 'Temperature',
            width: '8%',
            //sorter: (a, b) => a.Unique_Id.localeCompare(b.Unique_Id),
            render: (text) => <a>{text === ' ' ? '-' : text}</a>,
            align: 'center',
        },
        {
            title: 'Results',
            dataIndex: 'Results',
            key: 'Results',
            width: '15%',
            align: 'center',
            render: (text, record) => {
                const hasSingleItem = getResultsItems(record.Results);
                let itemValue = '';
                // if (hasSingleItem)
                itemValue = getResultsItemsValue(record.Results);
                // console.log(hasSingleItem)
                // console.log(itemValue)
                return (
                    <>
                        {hasSingleItem || itemValue === '_' ? (
                            <a>{itemValue === '_' ? '-' : itemValue}</a>
                        ) : (
                            <Tooltip title="Results" >
                                <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '00px', }} >
                                    <EyeOutlined style={{ fontSize: '18px' }}
                                        onClick={() => onReadParts(record)}
                                    />
                                </span>
                            </Tooltip>
                        )}
                    </>
                );
            }
        },


        // {
        //     title: 'Tested Date',
        //     dataIndex: 'TestedDate',
        //     key: 'TestedDate',
        //     align: 'center',
        //     render: (text) => {
        //         return (
        //             <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
        //         );
        //     },
        //     // sorter: (a, b) => moment(a.File_Creation_Date).valueOf() - moment(b.File_Creation_Date).valueOf()
        // },
        // {
        //     title: 'Reported Date',
        //     dataIndex: 'ReportedDate',
        //     key: 'UpdatedDate',
        //     align: 'center',
        //     render: (text) => {
        //         return (
        //             <div>{text ? moment(text).format('DD MMM YYYY') : '-'}</div>
        //         );
        //     },
        //     // sorter: (a, b) => a.Expiration_Date - b.Expiration_Date,
        //     //sorter: (a, b) => moment(a.Expiration_Date).valueOf() - moment(b.Expiration_Date).valueOf()
        // },

        // {
        //     title: 'Report',
        //     dataIndex: 'FileLink',
        //     key: 'FileLink',
        //     align: 'center',
        //     render: (text, record) => {
        //         return (
        //             <>
        //                 <Tooltip title="View  Report" >

        //                     <Button className='p-0 border-0' size='small' target='_blank'>
        //                         <FilePdfOutlined className='pdfIconRed' onClick={() => onClickPDFView(record)} />
        //                     </Button>

        //                 </Tooltip>
        //             </>
        //         )
        //     }
        // },
        {
            title: 'Actions',
            dataIndex: 'edit_delete',
            key: 'edit_delete',
            align: 'center',
            // fixed: 'right',
            // width: '200px',
            render: (text, record) => {
                return (

                    <div>

                        {/* <Tooltip title="Results" >
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                                <EyeOutlined style={{ fontSize: '18px' }}
                                    onClick={() => onReadParts(record)}
                                />
                            </span>
                        </Tooltip> */}

                        <Tooltip title="View  Report" >

                            <Button className='p-0 border-0' size='small' target='_blank' style={{ marginRight: '20px' }}>
                                <FilePdfOutlined className='pdfIconRed' onClick={() => onClickPDFView(record)} />
                            </Button>

                        </Tooltip>
                        <Tooltip title="Update">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover">
                                <EditOutlined style={{ paddingRight: '0px' }}
                                    onClick={() => onUpdateClick(record)}
                                />
                            </span>
                        </Tooltip>
                        {/* <Tooltip title="Delete">
                            <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                                <DeleteOutlined style={{ fontSize: '18px' }}
                                //onClick={() => showDeleteModal(record)}
                                />
                            </span>
                        </Tooltip> */}
                    </div>)
            },

        },

    ];


    const getResultsItems = (data) => {
        const dataArray = Object.entries(data)

            .map(([key, value]) => ({
                label: key,
                value: value
            }));

        if (dataArray.length == 1) {
            try {
                //JSON.parse(dataArray[0].value)

                if (typeof dataArray[0].value == 'object') {
                    //  console.log('json v', dataArray[0].value, data)
                    return false
                }
            }
            catch (error) {
                return false
            }

            return true
        }
        else
            return false

        // return   typeof data === 'object' && data !== null 
        //console.log(dataArray)
        //return dataArray.length == 1;
    };

    const getResultsItemsValue = (data) => {
        const dataArray = Object.entries(data)

            .map(([key, value]) => ({
                label: key,
                value: value
            }));

        return dataArray.length > 1 ? dataArray : dataArray[0]?.value || '_';
    };
    const onClickPDFView = (record) => {
        setSelectedTestRow(record)
        setShowPDFViewer(true)

    }


    useEffect(() => {
        var obj = {
            pageSize: 10,
            pageNumber: 1,
        }
        onProjectList(obj);
    }, [])
    useEffect(() => {

        // var obj = {
        //     pageSize: currentPagesize,
        //     pageNumber: 1,
        //     searchKey: searchCriteria
        // }
        // setCurrent(1)
        // onProjectList(obj)

        const isvalid = hasNonEmptyTag(searchCriteria)
        let obj = {
            pageSize: isvalid ? currentPagesize : prevPagesize,
            pageNumber: isvalid ? 1 : prevPageNumber,
            searchKey: searchCriteria
        };
        //console.log(obj);

        (async () => {
            // setSelectedMaterial([])
            if (isvalid) {
                setPrevPagesize(currentPagesize)
                setPrevPageNumber(current)
            }
            setCurrent(obj.pageNumber)
            await onProjectList(obj);
        })();


    }, [searchCriteria]);
    useEffect(() => {
        if (!showAddNewModal)
            addNewForm.resetFields();
    }, [showAddNewModal])
    const onReadParts = async (record) => {
        if (record) {
            // console.log(record)
            setSelectedRow(record)
            setResultModalVisible(true)
            //setshowTestPage(true)

        }

    }
    const onUpdateClick = (record) => {

        if (record) {
            // let editData = {
            //     projectName: record.ProjectName,
            //     projectNumber: record.ProjectNumber,

            // };
            addNewForm.setFieldsValue(record)
            addNewForm.setFieldsValue(record.Results)
            //console.log(record)
            seteditRecord(record)
            setIsEdit(true)
            // setShowAddNewModal(true)
        }

    }
    useEffect(() => {
        if (editRecord)
            setShowAddNewModal(true)

    }, [editRecord])

    function hasNonEmptyTag(jsonObj) {
        for (const key in jsonObj) {
            if (jsonObj.hasOwnProperty(key)) {
                const value = jsonObj[key];
                console.log(jsonObj);

                if (Array.isArray(value) && value.length > 0) {
                    return true;
                } else if (typeof value === 'string' && value.trim() !== '') {
                    return true;
                }
            }
        }
        return false;
    }
    // useEffect(() => {
    //     const isvalid = hasNonEmptyTag(searchCriteria)
    //     let obj = {
    //         pageSize: isvalid ? currentPagesize : prevPagesize,
    //         pageNumber: isvalid ? 1 : prevPageNumber,
    //         filterSearch: searchFilterCriteria
    //     };
    //     console.log(obj);

    //     (async () => {
    //         // setSelectedMaterial([])
    //         setPrevPagesize(currentPagesize)
    //         setPrevPageNumber(current)
    //         setCurrent(obj.pageNumber)
    //         await onProjectList(obj);
    //     })();

    // }, [searchFilterCriteria]);
    const handleSearchInputChange = (fieldName, value) => {
        setSearchCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    }
    const handleSearchFilterInputChange = (fieldName, value) => {
        setSearchFilterCriteria(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    }
    const handleTypeSearchInputChange = (fieldName, value) => {
        setSearchType(prevSearchCriteria => ({
            ...prevSearchCriteria,
            [fieldName]: value
        }));
    }
    const handleSearchDateSelect = (fieldName, date) => {
        const formattedDate = date ? moment(date).format('YYYY-MM-DD') : '';
        handleSearchInputChange(fieldName, formattedDate);
    };
    // const tagRender = (props) => {
    //     const { label, value, closable, onClose } = props;
    //     const onPreventMouseDown = (event) => {
    //         event.preventDefault();
    //         event.stopPropagation();
    //     };
    //     return (
    //         <Tag

    //             onMouseDown={onPreventMouseDown}
    //             closable={closable}
    //             onClose={onClose}
    //             style={{
    //                 marginBottom: '4px',
    //                 display: 'inline-flex',
    //                 alignItems: 'center',
    //             }}
    //         >
    //             {label}
    //         </Tag>
    //     );
    // };
    const tagRender = (props) => {
        return null
        // const { label, value, closable, onClose } = props;
        // return (
        //     <span onMouseDown={(e) => { e.preventDefault(); e.stopPropagation(); }}
        //         style={{
        //             marginTop: 30, padding: '3px 6px', borderRadius: 3,
        //             display: 'inline-block'
        //         }}>
        //         {label}
        //         {closable && <span onClick={onClose} style={{ marginLeft: 3, cursor: 'pointer' }}>x</span>}
        //     </span>
        // );
    };
    const handleDropdownVisibleChange = (open) => {
        if (!open) {

            // setMatrialSearchType('')
            // setProjectNameSearchType('')
            // setTestTypeSearchType('')
            // setTimelineSearchType('')
            // setTemperatureSearchType('')

        }
    };
    const updatedColumns = columns_feild1.map(column => {
        // const [selectedMaterials, setSelectedMaterials] = useState([]);
        // const handleMaterialComboboxChange = (value) => {
        //     setSelectedMaterials(value);
        // };

        if (column.dataIndex === 'TestedDate' || column.dataIndex === 'ReportedDate') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='date-picker'>
                            <DatePicker
                                allowClear
                                style={{ height: '30px', border: 'line', }}
                                format="DD-MM-YYYY"
                                placeholder='Search'
                                onChange={date => handleSearchDateSelect(column.dataIndex, date)}
                                bordered
                            />
                        </div>
                    </Space>
                ),
            };
        }
        else if (column.dataIndex === 'Material') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='maintenance-select1'>
                            <Select
                                className='maintenance-select1'
                                placeholder={'Select category'}
                                showSearch
                                allowClear
                                mode="multiple"
                                tagRender={tagRender}


                                //loading={isUserListLoading}
                                style={{
                                    height: '35px',
                                    position: 'relative',
                                    top: '0px',
                                    width: '100%'
                                }}
                                //optionFilterProp="children"
                                optionFilterProp={false}
                                onChange={(value) => handleMaterialComboboxChange(value)}
                                // onFocus={onGetAllUsers}
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                onSearch={(value) => handleSearchComboboxChange('Material', value)}
                                searchValue={matrialSearchType}
                                //value={matrialSearchType == '' ? selectedMaterial : matrialSearchType}
                                value={selectedMaterial}

                            // disabled={disableTypes}
                            >
                                {materialType &&
                                    materialType
                                        .sort((a, b) => a.Material.localeCompare(b.Material)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Material}> {/* Use item.category as value */}
                                                {item.Material}
                                            </Option>
                                        ))
                                }
                            </Select>
                            <div className="selected-tags">
                                {selectedMaterial.length == 0 && matrialSearchType != '' &&
                                    <div key={0} className='selected-tag'>
                                        {matrialSearchType}
                                        <span
                                            className='remove-tag'
                                            onClick={() => {

                                                setMatrialSearchType('')
                                                handleSearchComboboxChange('Material', '')
                                                //setSelectedMaterial(newSelectedMaterials);
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                }
                                {matrialSearchType === '' && selectedMaterial.map((material, index) => (
                                    <div key={index} className='selected-tag'>
                                        {material}
                                        <span
                                            className='remove-tag'
                                            onClick={() => {

                                                const newSelectedMaterials = selectedMaterial.filter(item => item !== material);
                                                handleMaterialComboboxChange(newSelectedMaterials)

                                                //setSelectedMaterial(newSelectedMaterials);
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                ))}

                            </div>
                        </div>
                    </Space>
                ),
            };
        }
        else if (column.dataIndex === 'TestType') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='maintenance-select1'>
                            <Select
                                //className='client-select'
                                placeholder={'Select category'}
                                showSearch
                                allowClear
                                //loading={isUserListLoading}

                                mode="multiple"
                                tagRender={tagRender}

                                //loading={isUserListLoading}
                                style={{
                                    height: '35px',
                                    position: 'relative',
                                    top: '0px',
                                    width: '100%'
                                }}
                                //optionFilterProp="children"
                                optionFilterProp={false}
                                onChange={(value) => handleCategoryComboboxChange(value)}
                                // onFocus={onGetAllUsers}
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                onSearch={(value) => handleSearchComboboxChange('TestType', value)}
                                searchValue={testTypeSearchType}
                                value={selectedCategory}
                            // disabled={disableTypes}
                            >
                                {categoryType &&
                                    categoryType
                                        .sort((a, b) => a.TestType.localeCompare(b.TestType)) // Sort by category
                                        .map((item, i) => (
                                            <Option style={{ width: '500px' }} key={i} value={item.TestType}> {/* Use item.category as value */}
                                                {item.TestType}
                                            </Option>
                                        ))
                                }
                            </Select>
                            <div className="selected-tags">
                                {selectedCategory.length == 0 && testTypeSearchType != '' &&
                                    <div key={0} className='selected-tag'>
                                        {testTypeSearchType}
                                        <span
                                            className='remove-tag'
                                            onClick={() => {

                                                setTestTypeSearchType('')
                                                handleSearchComboboxChange('TestType', '')
                                                //setSelectedMaterial(newSelectedMaterials);
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                }
                                {testTypeSearchType === '' && selectedCategory.map((testType, index) => (
                                    <div key={index} className='selected-tag'>
                                        {testType}
                                        <span
                                            className='remove-tag'
                                            onClick={() => {
                                                const newSelectedTestType = selectedCategory.filter(item => item !== testType);
                                                handleCategoryComboboxChange(newSelectedTestType)
                                                // setSelectedCategory(newSelectedTestType);
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Space>
                ),
            };
        }
        else if (column.dataIndex === 'ProjectName') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='maintenance-select1'>
                            <Select
                                //className='client-select'
                                placeholder={'Select category'}
                                showSearch
                                allowClear
                                mode="multiple"
                                tagRender={tagRender}

                                //loading={isUserListLoading}
                                style={{
                                    height: '35px',
                                    position: 'relative',
                                    top: '0px',
                                    width: '100%'
                                }}
                                //loading={isUserListLoading}

                                //optionFilterProp="children"
                                optionFilterProp={false}
                                onChange={(value) => handleProjectNameComboboxChange(value)}
                                // onFocus={onGetAllUsers}
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                onSearch={(value) => handleSearchComboboxChange('ProjectName', value)}
                                searchValue={projectNameSearchType}

                                value={selectedProjectName}

                            // disabled={disableTypes}
                            >
                                {projectNameType &&
                                    projectNameType
                                        .sort((a, b) => a.ProjectName.localeCompare(b.ProjectName)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.ProjectName}> {/* Use item.category as value */}
                                                {item.ProjectName}
                                            </Option>
                                        ))
                                }
                            </Select>
                            <div className="selected-tags">
                                {selectedProjectName.length == 0 && projectNameSearchType != '' &&
                                    <div key={0} className='selected-tag'>
                                        {projectNameSearchType}
                                        <span
                                            className='remove-tag'
                                            onClick={() => {

                                                setProjectNameSearchType('')
                                                handleSearchComboboxChange('ProjectName', '')
                                                //setSelectedMaterial(newSelectedMaterials);
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                }
                                {projectNameSearchType == '' && selectedProjectName.map((projectName, index) => (
                                    <div key={index} className='selected-tag'>
                                        {projectName}
                                        <span style={{ color: 'red' }}
                                            className='remove-tag'
                                            onClick={() => {
                                                const newSelectedProjectName = selectedProjectName.filter(item => item !== projectName);
                                                handleProjectNameComboboxChange(newSelectedProjectName)

                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Space>
                ),
            };
        }
        else if (column.dataIndex === 'Timeline') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='maintenance-select1'>
                            <Select
                                //className='client-select'
                                placeholder={'Select category'}
                                showSearch
                                allowClear
                                //loading={isUserListLoading}
                                tagRender={tagRender}
                                mode="multiple"
                                style={{
                                    height: '35px',
                                    position: 'relative',
                                    top: '0px',
                                    width: '100%'
                                }}
                                //optionFilterProp="children"
                                optionFilterProp={false}
                                onChange={(value) => handleTimelineComboboxChange(value)}
                                // onFocus={onGetAllUsers}
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                onSearch={(value) => handleSearchComboboxChange('Timeline', value)}
                                searchValue={timelineSearchType}

                                value={selectedTimeline}
                            // disabled={disableTypes}
                            >
                                {timelineType &&
                                    timelineType
                                        .sort((a, b) => a.Timeline.localeCompare(b.Timeline)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Timeline}> {/* Use item.category as value */}
                                                {item.Timeline}
                                            </Option>
                                        ))
                                }
                            </Select>
                            <div className="selected-tags">
                                {selectedTimeline && selectedTimeline.map((timeline, index) => (
                                    <div key={index} className='selected-tag'>
                                        {timeline}
                                        <span
                                            className='remove-tag'
                                            onClick={() => {
                                                const newSelectedTimeline = selectedTimeline.filter(item => item !== timeline);
                                                handleTimelineComboboxChange(newSelectedTimeline)
                                                // setSelectedCategory(newSelectedTestType);
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Space>
                ),
            };
        }
        else if (column.dataIndex === 'Temperature') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='maintenance-select1'>
                            <Select
                                //className='client-select'
                                placeholder={'Select category'}
                                showSearch
                                allowClear
                                tagRender={tagRender}
                                //loading={isUserListLoading}
                                mode="multiple"
                                style={{
                                    height: '35px',
                                    position: 'relative',
                                    top: '0px',
                                    width: '100%'
                                }}

                                //optionFilterProp="children"
                                optionFilterProp={false}
                                onChange={(value) => handleTemperarutureComboboxChange(value)}
                                // onFocus={onGetAllUsers}
                                onDropdownVisibleChange={handleDropdownVisibleChange}
                                onSearch={(value) => handleSearchComboboxChange('Temperature', value)}
                                searchValue={temperatureSearchType}

                                value={selectedTemperature}
                            // disabled={disableTypes}
                            >
                                {temperatureType &&
                                    temperatureType
                                        .sort((a, b) => a.Temperature.localeCompare(b.Temperature)) // Sort by category
                                        .map((item, i) => (
                                            <Option key={i} value={item.Temperature}> {/* Use item.category as value */}
                                                {item.Temperature}
                                            </Option>
                                        ))
                                }
                            </Select>
                            <div className="selected-tags">
                                {selectedTemperature && selectedTemperature.map((temperature, index) => (
                                    <div key={index} className='selected-tag'>
                                        {temperature}
                                        <span
                                            className='remove-tag'
                                            onClick={() => {
                                                const newSelectedTemperature = selectedTemperature.filter(item => item !== temperature);
                                                handleTemperarutureComboboxChange(newSelectedTemperature)
                                                // setSelectedCategory(newSelectedTestType);
                                            }}
                                        >
                                            X
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </Space>
                ),
            };
        }
        else if (column.dataIndex !== 'edit_delete' && column.dataIndex !== 'FileLink' && column.dataIndex !== 'Results') {
            return {
                ...column,
                title: (
                    <Space style={{ display: 'flow', alignItems: 'center', }}>
                        {column.title}
                        <div className='searchEquip'>
                            <Input
                                placeholder={'Search'}
                                value={searchCriteria[column.dataIndex]}
                                onChange={e => handleSearchInputChange(column.dataIndex, e.target.value)}
                                allowClear
                            />
                        </div>
                    </Space>
                ),
            };
        }

        else {
            return column;
        }
    });




    const handleMaterialComboboxChange = async (value) => {
        console.log(value)
        setMatrialSearchType('')
        setSelectedMaterial(value);
        await handleTypeSearchInputChange('Material', value)
        await handleSearchInputChange('Material', value)
    };
    const handleCategoryComboboxChange = async (value) => {
        setTestTypeSearchType('')
        setSelectedCategory(value);
        await handleTypeSearchInputChange('TestType', value)
        await handleSearchInputChange('TestType', value)
    };
    const handleProjectNameComboboxChange = async (value) => {
        setProjectNameSearchType('')
        setSelectedProjectName(value);
        await handleTypeSearchInputChange('ProjectName', value)
        await handleSearchInputChange('ProjectName', value)
    };
    const handleTimelineComboboxChange = async (value) => {
        setSelectedTimeline(value);
        await handleTypeSearchInputChange('Timeline', value)
        await handleSearchInputChange('Timeline', value)
    };
    const handleTemperarutureComboboxChange = async (value) => {

        setSelectedTemperature(value);
        await handleTypeSearchInputChange('Temperature', value)
        await handleSearchInputChange('Temperature', value)
    };
    const handleFileNameComboboxChange = async (value) => {
        setFileNameSearchType('')
        setSelectedFileName(value);
        await handleTypeSearchInputChange('Filename', value)
        await handleSearchInputChange('Filename', value)
    };
    const handleSearchComboboxChange = async (category, value) => {


        if (category === 'Material') {
            //await handleSearchInputChange('Material', value)
            //setSelectedMaterial([value])
            setMatrialSearchType(value)
            await handleTypeSearchInputChange('Material', value)
            await handleSearchInputChange('Material', value)
            // handleSearchFilterInputChange('Material', value)
        }
        else if (category === 'TestType') {
            setTestTypeSearchType(value)
            await handleTypeSearchInputChange('TestType', value)
            await handleSearchInputChange('TestType', value)
            // handleSearchFilterInputChange('TestType', value)

        }
        else if (category === 'ProjectName') {
            setProjectNameSearchType(value)
            await handleTypeSearchInputChange('ProjectName', value)
            await handleSearchInputChange('ProjectName', value)
            //handleSearchFilterInputChange('ProjectName', value)
        }
        else if (category === 'Filename') {
            setFileNameSearchType(value)
            await handleTypeSearchInputChange('Filename', value)
            await handleSearchInputChange('Filename', value)
        }
        else if (category === 'Timeline')
            setTimelineSearchType(value)
        else if (category === 'Temperature')
            setTemperatureSearchType(value)
    };



    // useEffect(() => {
    //     setSelectedCategory('');
    //     setSelectedTimeline('');
    //     setSelectedTemperature('');
    //     //setSearchCriteria('')

    // }, [selectedMaterial])
    useEffect(() => {
        (async () => {
            //console.log(selectedMaterial, selectedCategory, selectedProjectName)
            if (selectedMaterial.length == 0)
                await setMaterialType(await getCategoryList('Material', searchType));
            if (selectedCategory.length == 0)
                await setCategoryType(await getCategoryList('TestType', searchType));
            if (selectedProjectName.length == 0)
                await setProjectNameType(await getCategoryList('ProjectName', searchType));
            if (selectedTimeline.length == 0)
                await setTimelineType(await getCategoryList('Timeline', searchType));
            if (selectedTemperature.length == 0)
                await setTemperatureType(await getCategoryList('Temperature', searchType));
            if (selectedFileName.length == 0)
                await setFileNameType(await getCategoryList('Filename', searchType));
        })();


    }, [searchType])

    const getCategoryList = async (searchKeyExist, searchkey) => {
        try {
            const object = {
                header: searchKeyExist,
                searchKey: searchkey
            }
            //  console.log('Search key', object)
            //return;
            const response = await getCategoryListFromDatabse(object);
            // console.log(response)
            if (response) {

                return response;
            }
        } catch (error) {
            notification.error({
                message: 'Something went wrong. ' + searchKeyExist,
            });
            return []
        }
    };

    const renderKeyValuePairs = (obj, level = 0) => {
        return Object.entries(obj).map(([key, value]) => {
            if (value !== "_") {
                if (typeof value === 'object' && value !== null) {
                    // Recursively render nested objects
                    return (
                        <div key={key} style={{ marginLeft: `${level * 20}px` }}>
                            <strong>{key}</strong>:
                            {renderKeyValuePairs(value, level + 1)}
                        </div>
                    );
                } else {
                    // Render the key-value pair
                    return (
                        <div key={key} style={{ marginLeft: `${level * 20}px` }}>
                            <strong>{key}</strong>: {value}
                        </div>
                    );
                }
            }
            return null;
        });
    };

    const handleDownloadFilterTable = async (filterData) => {
        setExportLoading(true)
        //console.log(filterData)
        const data = await createExcelTemplate();
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Coating Technical Report.xlsx';
        link.click();
        setExportLoading(false)
    };
    const showDrawer = () => {
        setIsDrawerVisible(true);
    };

    const closeDrawer = () => {
        setIsDrawerVisible(false);
    };

    const createExcelTemplate = async () => {

        var obj = {
            searchKey: searchCriteria
        }
        const data = await getExportDataListFromDatabse(obj)
        if (data && data.length > 0) {
            const workbook = new ExcelJS.Workbook();
            const sheet = workbook.addWorksheet('Coating Technical Report');

            // Keys to skip
            const keysToSkip = ['ID', 'FileLink', 'totalCount', 'CreationDate', 'UpdatedDate'];

            // Function to flatten the object structure
            const flattenObject = (obj, parentKey = '', result = {}) => {
                for (const key in obj) {
                    const propName = parentKey ? `${parentKey}_${key}` : key;
                    if (keysToSkip.includes(key)) continue;
                    if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                        flattenObject(obj[key], propName, result);
                    } else {
                        result[propName] = obj[key];
                    }
                }
                return result;
            };

            const headers = new Set();
            const rows = [];

            data.forEach(item => {
                const flattenedItem = flattenObject(item);
                Object.keys(flattenedItem).forEach(key => {
                    if (!keysToSkip.includes(key)) headers.add(key);
                });
                rows.push(flattenedItem);
            });

            const orderedHeaders = Array.from(headers);
            Array.prototype.myJoin = function (seperator, start, end) {
                if (!start) start = 0;
                if (!end) end = this.length - 1;
                end++;
                return this.slice(start, end).join(seperator);
            };
            // Define columns with hierarchical headers
            sheet.columns = orderedHeaders.map(header => {
                if (header === ('Results_Result')) {
                    return { header: 'Results', key: header, width: 20 };
                } else if (header.startsWith('Results_Result')) {
                    const headerParts = header.split('_');
                    return { header: headerParts.myJoin(' -> ', 1, 2), key: header, width: 20 };
                } else {
                    const headerParts = header.split('_');
                    return { header: headerParts.join(' -> '), key: header, width: 20 };
                }
            });

            // Print the converted object
            //console.log('Converted Object', orderedHeaders, rows);

            // Add rows
            rows.forEach((rowData, index) => {
                const row = sheet.getRow(index + 2); // Start from the second row
                const orderedRowData = orderedHeaders.map(header => rowData[header] !== undefined ? rowData[header] : '');
                orderedRowData.forEach((value, cellIndex) => {
                    row.getCell(cellIndex + 1).value = value;
                });
                // console.log(row.values);
            });
            // Save the workbook to a buffer
            return await workbook.xlsx.writeBuffer();
        } else {
            notification.error({
                message: 'Filter data empty'
            });
        }
    };
    const countValue = (type, val) => {
        return projectList.filter(item => {
            if (type === 'Results') {
                return item[type].Result === val;
            } else {
                return item[type] === val;
            }
        }).length;
    };

    const onClearFilters = async () => {

        console.log(selectedMaterial, matrialSearchType)
        if (matrialSearchType != '') {
            setMatrialSearchType('')
            handleSearchComboboxChange('Material', '')
        }
        if (selectedMaterial.length > 0) {

            await handleMaterialComboboxChange([])
        }
        if (testTypeSearchType != '') {
            setTestTypeSearchType('')
            handleSearchComboboxChange('TestType', '')
        }
        if (selectedCategory.length != 0) {
            await handleCategoryComboboxChange([])
        }
        if (projectNameSearchType != '') {
            setProjectNameSearchType('')
            handleSearchComboboxChange('ProjectName', '')
        }
        if (selectedProjectName.length != 0) {
            await handleProjectNameComboboxChange([])
        }
        if (timelineSearchType != '') {
            setTimelineSearchType('')
            handleSearchComboboxChange('Timeline', '')
        }
        if (selectedTimeline.length != 0) {
            await handleTimelineComboboxChange([])
        }
        if (temperatureSearchType != '') {
            setTemperatureSearchType('')
            handleSearchComboboxChange('Temperature', '')
        }
        if (selectedTemperature.length != 0) {
            await handleTemperarutureComboboxChange([])
        }
        if (fileNameSearchType != '') {
            setFileNameSearchType('')
            handleSearchComboboxChange('Filename', '')
        }
        if (selectedFileName.length != 0) {
            await handleFileNameComboboxChange([])
        }

    }

    return (

        <div className="flex-auto">
            {!showTestPage && !showPDFViewer &&
                <div className="flex-auto">
                    <div className="d-flex mb-3 align-items-center tab-content-title">


                        <div className='back_heatp_modal' >
                            <Tooltip title={'Back'} >
                                <ArrowLeftOutlined
                                    onClick={() => {
                                        history.push('/technical');
                                    }}
                                />
                            </Tooltip>
                        </div>

                        <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '25px', paddingTop: '5px' }}>
                            Coating Test Repository
                        </Title>

                        <div className="new-calibration">

                            <Text className="ps-3 add-new-calibration" style={{ marginTop: '10px', marginRight: '20px' }}>Filters Count: {listCount} / {listTotalCount}</Text>
                            {/* <Tag> Results:{countValue('Results', '_')}</Tag> */}
                            {/* <Tag> status: {listCount}</Tag>
                                <Tag> status: {listCount}</Tag> */}

                            <Button shape="round" key="apply" type="primary" onClick={showDrawer} style={{ marginLeft: '20px' }}>
                                <FilterOutlined style={{ fontSize: '20px' }}> </FilterOutlined>Filter
                            </Button>

                            <Button shape="round" key="apply" type="primary" style={{ marginLeft: '20px' }}
                                onClick={() => handleDownloadFilterTable(projectList)}
                                className="ps-3 add-new-calibration" loading={exportLoading}>

                                Export to Excel
                            </Button>
                            {/* <Text onClick={showDrawer} className="ps-3" >
                                <FilterOutlined className='TestrepoIconRed' style={{ marginRight: '5px' }}> </FilterOutlined>
                                Filter
                            </Text> */}



                        </div>


                    </div>
                    <div className="custom-line" />
                    {/*
                        <div className='addTeamDetails'>
                            <Table columns={updatedColumns}

                                className="h-100 pipeline-table equip_table equipPaginationTable"
                                scroll={{ y: 'calc(100vh - 230px)' }}
                                rowKey={'ID'}
                                pagination={
                                    listCount > 10 ? {
                                        position: ['bottomRight'],
                                        showSizeChanger: true,
                                        total: listCount,
                                        defaultPageSize: 10,
                                        defaultCurrent: 1,

                                        onChange: async (pageNumber, pageSize) => {
                                            // console.log(licenseTotalCount, pageNumber, pageSize)
                                            var obj = {
                                                pageSize: pageSize,
                                                pageNumber: pageNumber
                                            }
                                            await onProjectList(obj)

                                        }
                                    }
                                        : false
                                }

                                dataSource={[...projectList]}
                                loading={isLoading}

                            />

                        </div> */}

                    <Row gutter={24}>
                        <Col span={24} >
                            <div className='addTeamDetails'>
                                <Table columns={columns_feild1}

                                    className="h-100 pipeline-table equipPaginationTable"
                                    scroll={{ y: 'calc(100vh - 200px)' }}
                                    // scroll={{ x: '100%', y: '100%' }}
                                    width='100%'
                                    rowKey={'ID'}
                                    pagination={
                                        listCount > 10 ? {
                                            position: ['bottomRight'],
                                            showSizeChanger: true,
                                            className: 'fixed-pagination',
                                            total: listCount,
                                            defaultPageSize: 10,
                                            defaultCurrent: 1,
                                            current: current,
                                            pageSize: currentPagesize,
                                            onChange: async (pageNumber, pageSize, sorter) => {
                                                // console.log(licenseTotalCount, pageNumber, pageSize)
                                                setCurrent(pageNumber);
                                                setCurrentPagesize(pageSize);


                                                var obj = {
                                                    pageSize: pageSize,
                                                    pageNumber: pageNumber,
                                                    searchKey: searchCriteria

                                                }


                                                await onProjectList(obj)

                                            }
                                        }
                                            : false
                                    }
                                    dataSource={[...projectList]}
                                    loading={isLoading}

                                />

                            </div>
                        </Col>
                    </Row>
                </div>
            }
            {
                showAddNewModal &&
                <UpdateReport
                    showAddNewModal={showAddNewModal}
                    setShowAddNewModal={setShowAddNewModal}
                    addNewForm={addNewForm}
                    isEdit={isEdit}
                    setIsEdit={setIsEdit}
                    seteditRecord={seteditRecord}
                    editRecord={editRecord}

                >

                </UpdateReport>
            }
            {showTestPage &&
                <TechnicalReportPage
                    showTestPage={showTestPage}
                    setshowTestPage={setshowTestPage}
                    setSelectedRow={setSelectedRow}
                    selectedRow={selectedRow}
                >
                </TechnicalReportPage>

            }
            {showPDFViewer &&
                <div style={{ height: '100%' }}>
                    <div>
                        <div className="d-flex mb-3 align-items-center">
                            <div className='back_heatp_modal' >
                                <Tooltip title={'Back'} >
                                    <ArrowLeftOutlined onClick={() => {

                                        setShowPDFViewer(false)
                                        //  history.push('/TechnicalPage');
                                    }} />
                                </Tooltip>
                            </div>

                            <Title className="mb-0 add-new-calibration" level={3} style={{ paddingLeft: '30px' }}>
                                {showPDFViewer ? 'Report Viewer' : "Projects"}
                            </Title>

                            <div className="new-calibration">



                            </div>


                        </div>
                        <div className='pdf-container'>
                            <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js'>
                                <Viewer
                                    fileUrl={selectedTestRow.FileLink}
                                    plugins={[defaultLayoutPluginInstance]}
                                />
                            </Worker>
                        </div>
                    </div>

                </div>
            }
            <Modal
                className="pipeline-modal"
                title='Results'
                centered
                visible={resultModalVisible}
                footer={[
                    <Button key="apply" type="primary" onClick={() => setResultModalVisible(false)}>
                        {'Ok'}
                    </Button>,

                ]}
            >
                <div>
                    {resultModalVisible && renderKeyValuePairs(selectedRow.Results)}
                </div>
            </Modal>


            <Drawer
                title="Filter Options"
                placement="right"
                onClose={closeDrawer}
                visible={isDrawerVisible}
                width='40%'
                className='pipeline-modal Filter-Drawer'
            >
                <Space style={{ display: 'flow', alignItems: 'center', }}>
                    {"Material"}
                    <div className='maintenance-select1'>
                        <Select
                            className='maintenance-select1'
                            placeholder={'Select category'}
                            showSearch
                            allowClear
                            mode="multiple"
                            tagRender={tagRender}


                            //loading={isUserListLoading}
                            style={{
                                height: '35px',
                                position: 'relative',
                                top: '0px',
                                width: '100%'
                            }}
                            //optionFilterProp="children"
                            optionFilterProp={false}
                            onChange={(value) => handleMaterialComboboxChange(value)}
                            // onFocus={onGetAllUsers}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                            onSearch={(value) => handleSearchComboboxChange('Material', value)}
                            searchValue={matrialSearchType}
                            //value={matrialSearchType == '' ? selectedMaterial : matrialSearchType}
                            value={selectedMaterial}

                        // disabled={disableTypes}
                        >
                            {materialType &&
                                materialType
                                    .sort((a, b) => a.Material.localeCompare(b.Material)) // Sort by category
                                    .map((item, i) => (
                                        <Option key={i} value={item.Material}> {/* Use item.category as value */}
                                            {item.Material}
                                        </Option>
                                    ))
                            }
                        </Select>
                        <div className="selected-tags">
                            {selectedMaterial.length == 0 && matrialSearchType != '' &&
                                <div key={0} className='selected-tag'>
                                    {matrialSearchType}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {

                                            setMatrialSearchType('')
                                            handleSearchComboboxChange('Material', '')
                                            //setSelectedMaterial(newSelectedMaterials);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            }
                            {matrialSearchType === '' && selectedMaterial.map((material, index) => (
                                <div key={index} className='selected-tag'>
                                    {material}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {

                                            const newSelectedMaterials = selectedMaterial.filter(item => item !== material);
                                            handleMaterialComboboxChange(newSelectedMaterials)

                                            //setSelectedMaterial(newSelectedMaterials);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            ))}

                        </div>
                    </div>
                </Space>
                <Space style={{ display: 'flow', alignItems: 'center', }}>
                    {'Test Type'}
                    <div className='maintenance-select1'>
                        <Select
                            //className='client-select'
                            placeholder={'Select category'}
                            showSearch
                            allowClear
                            //loading={isUserListLoading}

                            mode="multiple"
                            tagRender={tagRender}

                            //loading={isUserListLoading}
                            style={{
                                height: '35px',
                                position: 'relative',
                                top: '0px',
                                width: '100%'
                            }}
                            //optionFilterProp="children"
                            optionFilterProp={false}
                            onChange={(value) => handleCategoryComboboxChange(value)}
                            // onFocus={onGetAllUsers}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                            onSearch={(value) => handleSearchComboboxChange('TestType', value)}
                            searchValue={testTypeSearchType}
                            value={selectedCategory}
                        // disabled={disableTypes}
                        >
                            {categoryType &&
                                categoryType
                                    .sort((a, b) => a.TestType.localeCompare(b.TestType)) // Sort by category
                                    .map((item, i) => (
                                        <Option style={{ width: '500px' }} key={i} value={item.TestType}> {/* Use item.category as value */}
                                            {item.TestType}
                                        </Option>
                                    ))
                            }
                        </Select>
                        <div className="selected-tags">
                            {selectedCategory.length == 0 && testTypeSearchType != '' &&
                                <div key={0} className='selected-tag'>
                                    {testTypeSearchType}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {

                                            setTestTypeSearchType('')
                                            handleSearchComboboxChange('TestType', '')
                                            //setSelectedMaterial(newSelectedMaterials);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            }
                            {testTypeSearchType === '' && selectedCategory.map((testType, index) => (
                                <div key={index} className='selected-tag'>
                                    {testType}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {
                                            const newSelectedTestType = selectedCategory.filter(item => item !== testType);
                                            handleCategoryComboboxChange(newSelectedTestType)
                                            // setSelectedCategory(newSelectedTestType);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Space>
                <Space style={{ display: 'flow', alignItems: 'center', }}>
                    {'Project Name'}
                    <div className='maintenance-select1'>
                        <Select
                            //className='client-select'
                            placeholder={'Select category'}
                            showSearch
                            allowClear
                            mode="multiple"
                            tagRender={tagRender}

                            //loading={isUserListLoading}
                            style={{
                                height: '35px',
                                position: 'relative',
                                top: '0px',
                                width: '100%'
                            }}
                            //loading={isUserListLoading}

                            //optionFilterProp="children"
                            optionFilterProp={false}
                            onChange={(value) => handleProjectNameComboboxChange(value)}
                            // onFocus={onGetAllUsers}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                            onSearch={(value) => handleSearchComboboxChange('ProjectName', value)}
                            searchValue={projectNameSearchType}

                            value={selectedProjectName}

                        // disabled={disableTypes}
                        >
                            {projectNameType &&
                                projectNameType
                                    .sort((a, b) => a.ProjectName.localeCompare(b.ProjectName)) // Sort by category
                                    .map((item, i) => (
                                        <Option key={i} value={item.ProjectName}> {/* Use item.category as value */}
                                            {item.ProjectName}
                                        </Option>
                                    ))
                            }
                        </Select>
                        <div className="selected-tags">
                            {selectedProjectName.length == 0 && projectNameSearchType != '' &&
                                <div key={0} className='selected-tag'>
                                    {projectNameSearchType}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {

                                            setProjectNameSearchType('')
                                            handleSearchComboboxChange('ProjectName', '')
                                            //setSelectedMaterial(newSelectedMaterials);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            }
                            {projectNameSearchType == '' && selectedProjectName.map((projectName, index) => (
                                <div key={index} className='selected-tag'>
                                    {projectName}
                                    <span style={{ color: 'red' }}
                                        className='remove-tag'
                                        onClick={() => {
                                            const newSelectedProjectName = selectedProjectName.filter(item => item !== projectName);
                                            handleProjectNameComboboxChange(newSelectedProjectName)

                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Space>
                <Space style={{ display: 'flow', alignItems: 'center', }}>
                    {"Duration"}
                    <div className='maintenance-select1'>
                        <Select
                            //className='client-select'
                            placeholder={'Select category'}
                            showSearch
                            allowClear
                            //loading={isUserListLoading}
                            tagRender={tagRender}
                            mode="multiple"
                            style={{
                                height: '35px',
                                position: 'relative',
                                top: '0px',
                                width: '100%'
                            }}
                            //optionFilterProp="children"
                            optionFilterProp={false}
                            onChange={(value) => handleTimelineComboboxChange(value)}
                            // onFocus={onGetAllUsers}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                            onSearch={(value) => handleSearchComboboxChange('Timeline', value)}
                            searchValue={timelineSearchType}

                            value={selectedTimeline}
                        // disabled={disableTypes}
                        >
                            {timelineType &&
                                timelineType
                                    .sort((a, b) => a.Timeline.localeCompare(b.Timeline)) // Sort by category
                                    .map((item, i) => (
                                        <Option key={i} value={item.Timeline}> {/* Use item.category as value */}
                                            {item.Timeline}
                                        </Option>
                                    ))
                            }
                        </Select>
                        <div className="selected-tags">
                            {selectedTimeline.length == 0 && timelineSearchType != '' &&
                                <div key={0} className='selected-tag'>
                                    {projectNameSearchType}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {

                                            setTimelineSearchType('')
                                            handleSearchComboboxChange('Timeline', '')
                                            //setSelectedMaterial(newSelectedMaterials);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            }
                            {timelineSearchType == '' && selectedTimeline.map((timeline, index) => (
                                <div key={index} className='selected-tag'>
                                    {timeline}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {
                                            const newSelectedTimeline = selectedTimeline.filter(item => item !== timeline);
                                            handleTimelineComboboxChange(newSelectedTimeline)
                                            // setSelectedCategory(newSelectedTestType);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>

                        <div className="selected-tags">
                            {selectedTimeline && selectedTimeline.map((timeline, index) => (
                                <div key={index} className='selected-tag'>
                                    {timeline}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {
                                            const newSelectedTimeline = selectedTimeline.filter(item => item !== timeline);
                                            handleTimelineComboboxChange(newSelectedTimeline)
                                            // setSelectedCategory(newSelectedTestType);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Space>
                <Space style={{ display: 'flow', alignItems: 'center', }}>
                    {'Temperature'}
                    <div className='maintenance-select1'>
                        <Select
                            //className='client-select'
                            placeholder={'Select category'}
                            showSearch
                            allowClear
                            tagRender={tagRender}
                            //loading={isUserListLoading}
                            mode="multiple"
                            style={{
                                height: '35px',
                                position: 'relative',
                                top: '0px',
                                width: '100%'
                            }}

                            //optionFilterProp="children"
                            optionFilterProp={false}
                            onChange={(value) => handleTemperarutureComboboxChange(value)}
                            // onFocus={onGetAllUsers}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                            onSearch={(value) => handleSearchComboboxChange('Temperature', value)}
                            searchValue={temperatureSearchType}

                            value={selectedTemperature}
                        // disabled={disableTypes}
                        >
                            {temperatureType &&
                                temperatureType
                                    .sort((a, b) => a.Temperature.localeCompare(b.Temperature)) // Sort by category
                                    .map((item, i) => (
                                        <Option key={i} value={item.Temperature}> {/* Use item.category as value */}
                                            {item.Temperature}
                                        </Option>
                                    ))
                            }
                        </Select>
                        <div className="selected-tags">
                            {selectedTemperature.length == 0 && temperatureSearchType != '' &&
                                <div key={0} className='selected-tag'>
                                    {temperatureSearchType}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {

                                            setTemperatureSearchType('')
                                            handleSearchComboboxChange('Temperature', '')
                                            //setSelectedMaterial(newSelectedMaterials);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            }
                            {temperatureSearchType == '' && selectedTemperature.map((temperature, index) => (
                                <div key={index} className='selected-tag'>
                                    {temperature}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {
                                            const newSelectedTemperature = selectedTemperature.filter(item => item !== temperature);
                                            handleTemperarutureComboboxChange(newSelectedTemperature)
                                            // setSelectedCategory(newSelectedTestType);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Space>
                <Space style={{ display: 'flow', alignItems: 'center', }}>
                    {'File Name'}
                    <div className='maintenance-select1'>
                        <Select
                            //className='client-select'
                            placeholder={'Select category'}
                            showSearch
                            allowClear
                            tagRender={tagRender}
                            //loading={isUserListLoading}
                            mode="multiple"
                            style={{
                                height: '35px',
                                position: 'relative',
                                top: '0px',
                                width: '100%'
                            }}

                            //optionFilterProp="children"
                            optionFilterProp={false}
                            onChange={(value) => handleFileNameComboboxChange(value)}
                            // onFocus={onGetAllUsers}
                            onDropdownVisibleChange={handleDropdownVisibleChange}
                            onSearch={(value) => handleSearchComboboxChange('Filename', value)}
                            searchValue={fileNameSearchType}

                            value={selectedFileName}
                        // disabled={disableTypes}
                        >
                            {fileNameType &&
                                fileNameType
                                    .sort((a, b) => a.Filename.localeCompare(b.Filename)) // Sort by category
                                    .map((item, i) => (
                                        <Option key={i} value={item.Filename}> {/* Use item.category as value */}
                                            {item.Filename}
                                        </Option>
                                    ))
                            }
                        </Select>
                        <div className="selected-tags">

                            {selectedFileName.length == 0 && fileNameSearchType != '' &&
                                <div key={0} className='selected-tag'>
                                    {fileNameSearchType}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {

                                            setFileNameSearchType('')
                                            handleSearchComboboxChange('Filename', '')
                                            //setSelectedMaterial(newSelectedMaterials);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            }
                            {projectNameSearchType == '' && selectedFileName.map((filename, index) => (
                                <div key={index} className='selected-tag'>
                                    {filename}
                                    <span
                                        className='remove-tag'
                                        onClick={() => {
                                            const newSelectedFileName = selectedFileName.filter(item => item !== filename);
                                            handleFileNameComboboxChange(newSelectedFileName)
                                            // setSelectedCategory(newSelectedTestType);
                                        }}
                                    >
                                        X
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Space>
                <div className="ps-3 add-new-calibration-filter">

                    <div className="button-container">

                        <Button type="primary" style={{ marginTop: 16, marginLeft: 10 }}
                            onClick={(e) => onClearFilters()}
                        >
                            Clear Filters
                        </Button>
                        <Button type="primary" style={{ marginTop: 16, marginLeft: 10 }}
                            onClick={(e) => closeDrawer()}
                        >
                            Sumit
                        </Button>
                    </div>
                </div>
            </Drawer>

        </div >

    )
};
const mapStateToProps = ({ CrceTechnicalReducer }) => {
    const projectList = get(CrceTechnicalReducer, 'ProjectList', []);
    const isLoading = get(CrceTechnicalReducer, 'isProjectListLoading', false);

    const listCount = get(CrceTechnicalReducer, 'ProjectListCount', null);
    const listTotalCount = get(CrceTechnicalReducer, 'ProjectListTotalCount', null);


    return {
        projectList,
        isLoading,
        listCount,
        listTotalCount

    }
}

const mapDispatchToProps = {
    onProjectList: fetchProjectList,
}

export default connect(mapStateToProps, mapDispatchToProps)(TechnicalPage);
