export const FETCH_ADMIN_EQUIPMENT_SNO = '@@equipmentSno/fetch-admin-equipmentSno';
export const FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS = '@@equipmentSno/fetch-admin-equipmentSno-success';
export const FETCH_ADMIN_EQUIPMENT_SNO_FAILURE = '@@equipmentSno/fetch-admin-equipmentSno-failure';

export const FETCH_ADMIN_EQUIPMENT_TYPE_SNO = '@@equipmentTypeSno/fetch-admin-equipmentTypeSno';
export const FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS = '@@equipmentTypeSno/fetch-admin-equipmentTypeSno-success';
export const FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE = '@@equipmentTypeSno/fetch-admin-equipmentTypeSno-failure';

export const ADD_EQUIPMENT_SNO = '@@equipmentSno/add-equipmentSno';
export const ADD_EQUIPMENT_SNO_SUCCESS = '@@equipmentSno/add-equipmentSno-success';
export const ADD_EQUIPMENT_SNO_FAILURE = '@@equipmentSno/add-equipmentSno-failure';

export const UPDATE_EQUIPMENT_SNO = '@@equipmentSno/update-equipmentSno';
export const UPDATE_EQUIPMENT_SNO_SUCCESS = '@@equipmentSno/update-equipmentSno-success';
export const UPDATE_EQUIPMENT_SNO_FAILURE = '@@equipmentSno/update-equipmentSno-failure';

export const DELETE_EQUIPMENT_SNO = '@@equipmentSno/delete-equipmentSno';
export const DELETE_EQUIPMENT_SNO_SUCCESS = '@@equipmentSno/delete-equipmentSno-success';
export const DELETE_EQUIPMENT_SNO_FAILURE = '@@equipmentSno/delete-equipmentSno-failure';