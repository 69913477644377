/* eslint-disable react/prop-types */
import React from 'react';
import { Tabs, Input, Typography } from 'antd';
import ProductionJoints from '../components/DSR/ProductionJoints';
import DownTime from '../components/DSR/DownTime';
import EquipmentUsage from '../components/DSR/EquipmentUsage';
import EhsIssuesAndCorrectiveActions from '../components/DSR/EhsIssuesAndCorrectiveActions';
import ConumablesAndPpeUsage from '../components/DSR/ConumablesAndPpeUsage';
import TechnicianLog from '../components/DSR/TechnicianLog';
import DsrMaterialConsumption from '../components/DSR/DsrMaterialConsumption';
import ToolBox from '../components/DSR/ToolBox';
import { connect } from 'react-redux';
import { get } from 'lodash';

const { TabPane } = Tabs;

const DSRTabList = (props) => {

    const {
        selectedProject, selectedData, isFetchingDsrMaterialConsumption, dsrMaterialConsumption, dsrSowId
    } = props;
    const { Text } = Typography;
    
    const commentsBar = (dayDsrShiftTitle, nightDsrShiftTitle, dayDsrShiftComment, nightDsrShift) => {
        return (
            <div style={{ display: "flex", justifyContent: 'space-between', marginTop: '5px', marginLeft:'30px',marginRight:'30px' }}>
                <div style={{ width: '100%', marginRight: '10px' }}>
                    <Text style={{ color: '#EF314C' }}>{dayDsrShiftTitle}</Text>
                    <Input.TextArea rows={2} value={dayDsrShiftComment} />
                </div>
                <div style={{ width: '100%' }}>
                    <Text style={{ color: '#EF314C' }}>{nightDsrShiftTitle}</Text>
                    <Input.TextArea rows={2} value={nightDsrShift} />
                </div>
            </div>
        );
    }
    

    return (
        <div>
            {selectedData.isSkipped != 1 ?
                <Tabs className="custom-tab h-100" defaultActiveKey="1">
                    <TabPane tab="Material Consumption" key="1" style={{ position: 'relative' }}>
                        <DsrMaterialConsumption selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                        {
                            <div style={{ display: "flex",justifyContent:'space-between', marginTop:'10px', marginLeft:'30px',marginRight:'30px'}}>
                                <div style={{ width: '100%', marginRight: '5px'}}>
                                    <Text style={{color:'#EF314C'}}>Material Day Shift Comments</Text>
                                    <Input.TextArea rows={2} value={dsrMaterialConsumption[0]?.dlmDayShiftComments} />
                                </div>
                                <div style={{ width: '100%' }}>
                                    <Text style={{color:'#EF314C'}}>Material Night Shift Comments</Text>
                                    <Input.TextArea rows={2} value={dsrMaterialConsumption[0]?.dlmNightShiftComments} />
                                </div>
                            </div>
                        }
                        { commentsBar('DSR Day Shift Comments','DSR Night Shift Comments', selectedData?.dsrDayShiftComments, selectedData?.dsrNightShiftComments) }
                    </TabPane>
                    <TabPane tab="Production joints" key="2" style={{ position: 'relative' }}>
                        <ProductionJoints selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                        { commentsBar('DSR Day Shift Comments','DSR Night Shift Comments', selectedData?.dsrDayShiftComments, selectedData?.dsrNightShiftComments) }
                    </TabPane>
                    <TabPane tab="Equipment usage" key="3" style={{ position: 'relative' }}>
                        <EquipmentUsage selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                        { commentsBar('DSR Day Shift Comments','DSR Night Shift Comments', selectedData?.dsrDayShiftComments, selectedData?.dsrNightShiftComments) }
                    </TabPane>
                    <TabPane tab="EHS Issues & Corrective Actions" key="4" style={{ position: 'relative' }}>
                        <EhsIssuesAndCorrectiveActions selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                        { commentsBar('DSR Day Shift Comments','DSR Night Shift Comments', selectedData?.dsrDayShiftComments, selectedData?.dsrNightShiftComments) }
                    </TabPane>
                    <TabPane tab="Down time" key="5" style={{ position: 'relative' }}>
                        <DownTime selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                        { commentsBar('DSR Day Shift Comments','DSR Night Shift Comments', selectedData?.dsrDayShiftComments, selectedData?.dsrNightShiftComments) }
                    </TabPane>
                    <TabPane tab="Consumables & PPE Usage" key="6" style={{ position: 'relative' }}>
                        <ConumablesAndPpeUsage selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                        { commentsBar('DSR Day Shift Comments','DSR Night Shift Comments', selectedData?.dsrDayShiftComments, selectedData?.dsrNightShiftComments) }
                    </TabPane>
                    <TabPane tab="Technician Log" key="7" style={{ position: 'relative' }}>
                        <TechnicianLog selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                        { commentsBar('DSR Day Shift Comments','DSR Night Shift Comments', selectedData?.dsrDayShiftComments, selectedData?.dsrNightShiftComments) }
                    </TabPane>
                    <TabPane tab="ToolBox" key="8" style={{ position: 'relative' }}>
                        <ToolBox selectedProject={selectedProject} selectedData={selectedData} dsrSowId={dsrSowId} />
                        { commentsBar('DSR Day Shift Comments','DSR Night Shift Comments', selectedData?.dsrDayShiftComments, selectedData?.dsrNightShiftComments) }
                    </TabPane>
                </Tabs>
                :

                <div style={{ margin: "20px 0", display: 'grid' }}>
                    <p style={{ display: 'inline-flex' }}>
                        <Text style={{ color: '#EF314C', fontSize: 16, fontWeight: '600' }}>DSR Skipped</Text>

                    </p>
                </div>
            }
            <div style={{ display: "flex",justifyContent:'space-between', marginLeft:'30px',marginRight:'30px' }}>
                {selectedData.managerSignatureS3Object && selectedData.managerSignatureS3Object.objectUrl &&
                    <div style={{ width: '100%', marginRight: '10px'}}>
                        <Text>Manager Signature:</Text>
                        <img src={selectedData.managerSignatureS3Object.objectUrl} alt="manager signature" style={{ width: '30%', height:'100px' }} />
                    </div>
                }
                {selectedData.clientSignatureS3Object && selectedData.clientSignatureS3Object.objectUrl &&
                    <div style={{ width: '100%'}}>
                        <Text>Client Signature:</Text>
                        <img src={selectedData.clientSignatureS3Object.objectUrl} alt="client signature" style={{ width: '30%', height:'100px' }} />
                    </div>
                }
            </div>
        </div>
    );
}

const mapStateToProps = ({ materialConsumptionReducer }) => {
    const dsrMaterialConsumption = get(materialConsumptionReducer, 'dsrMaterialConsumption', []);
    const isFetchingDsrMaterialConsumption = get(materialConsumptionReducer, 'isFetchingDsrMaterialConsumption', false);

    return {
        dsrMaterialConsumption, isFetchingDsrMaterialConsumption
    }
}

export default connect(mapStateToProps, null)(DSRTabList);