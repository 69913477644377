import get from 'lodash/get';
import {
    ADD_EQUIPMENT_SNO,
    ADD_EQUIPMENT_SNO_FAILURE,
    ADD_EQUIPMENT_SNO_SUCCESS,
    DELETE_EQUIPMENT_SNO,
    DELETE_EQUIPMENT_SNO_FAILURE,
    DELETE_EQUIPMENT_SNO_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_SNO,
    FETCH_ADMIN_EQUIPMENT_SNO_FAILURE,
    FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS,
    FETCH_ADMIN_EQUIPMENT_TYPE_SNO,
    FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE,
    FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS,
    UPDATE_EQUIPMENT_SNO,
    UPDATE_EQUIPMENT_SNO_FAILURE,
    UPDATE_EQUIPMENT_SNO_SUCCESS
} from './types';

const initialState = {
    adminEquipSnoLoading: false,
    isAddEquipSnoLoading: false,
    isSerialNoUpdateLoading: false,
    isSerialNoDeleteLoading: false,
    adminEquipTypeSnoLoad: false
};

const adminEquipSnoReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_ADMIN_EQUIPMENT_SNO: {
            return {
                ...state,
                adminEquipSnoLoading: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_SNO_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipSnoData: resData.rows,
                adminEquipSnoLoading: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_SNO_FAILURE: {
            return {
                ...state,
                adminEquipSnoLoading: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_TYPE_SNO: {
            return {
                ...state,
                adminEquipTypeSnoLoad: true
            }
        }

        case FETCH_ADMIN_EQUIPMENT_TYPE_SNO_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                adminEquipTypeSnoData: resData.rows,
                adminEquipTypeSnoLoad: false
            }
        }

        case FETCH_ADMIN_EQUIPMENT_TYPE_SNO_FAILURE: {
            return {
                ...state,
                adminEquipTypeSnoLoad: false
            }
        }

        case ADD_EQUIPMENT_SNO: {
            return {
                ...state,
                isAddEquipSnoLoading: true
            }
        }

        case ADD_EQUIPMENT_SNO_SUCCESS: {
            return {
                ...state,
                isAddEquipSnoLoading: false
            }
        }

        case ADD_EQUIPMENT_SNO_FAILURE: {
            return {
                ...state,
                isAddEquipSnoLoading: false
            }
        }

        case UPDATE_EQUIPMENT_SNO: {
            return {
                ...state,
                isSerialNoUpdateLoading: true
            };
        }

        case UPDATE_EQUIPMENT_SNO_FAILURE: {
            return {
                ...state,
                isSerialNoUpdateLoading: false
            };
        }

        case UPDATE_EQUIPMENT_SNO_SUCCESS: {
            return {
                ...state,
                isSerialNoUpdateLoading: false
            }
        }

        case DELETE_EQUIPMENT_SNO: {
            return {
                ...state,
                isSerialNoDeleteLoading: true
            };
        }

        case DELETE_EQUIPMENT_SNO_FAILURE: {
            return {
                ...state,
                isSerialNoDeleteLoading: false
            };
        }

        case DELETE_EQUIPMENT_SNO_SUCCESS: {
            return {
                ...state,
                isSerialNoDeleteLoading: false
            }
        }

        default: {
            return state;
        }
    }
}

export default adminEquipSnoReducer;