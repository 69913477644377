import {
  FETCH_SPARES_LIST, FETCH_SPARES_LIST_SUCCESS, FETCH_SPARES_LIST_FAILURE, UPDATE_DASHBOARD_SPARE, DELETE_DASHBOARD_SPARE, FETCH_SPARE_EQUIPTYPE_LIST, FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS, FETCH_SPARE_EQUIPTYPE_LIST_FAILURE, FETCH_SPARE_EQUIPSN_LIST, FETCH_SPARE_EQUIPSN_LIST_SUCCESS, FETCH_SPARE_EQUIPSN_LIST_FAILURE, FETCH_SPARE_SAPKITN_LIST, FETCH_SPARE_SAPKITN_LIST_SUCCESS, FETCH_SPARE_SAPKITN_LIST_FAILURE, FETCH_SPARE_SAPNO_LIST, FETCH_SPARE_SAPNO_LIST_SUCCESS, FETCH_SPARE_SAPNO_LIST_FAILURE, ADD_SPARE_KIT, ADD_SPARE_KIT_SUCCESS, ADD_SPARE_KIT_FAILURE, ADD_SPARE_INDV, ADD_SPARE_INDV_SUCCESS, ADD_SPARE_INDV_FAILURE, PUBLISH_DASHBOARD_LIST, FETCH_EQUIPSN_LIST, FETCH_EQUIPSN_LIST_SUCCESS, FETCH_EQUIPSN_LIST_FAILURE, FETCH_EXCEL_SPARES_LIST, FETCH_EXCEL_SPARES_LIST_SUCCESS, FETCH_EXCEL_SPARES_LIST_FAILURE
  //  FETCH_SPARE_EQUIP_LIST, FETCH_SPARE_EQUIP_LIST_SUCCESS, FETCH_SPARE_EQUIP_LIST_FAILURE, ADD_SPARE, ADD_SPARE_SUCCESS, ADD_SPARE_FAILURE
} from './types';

const fetchSparesDashBoardList = (payload) => ({
  type: FETCH_SPARES_LIST, ...payload
});

const fetchSparesDashBoardListSuccess = (payload) => ({
  type: FETCH_SPARES_LIST_SUCCESS,
  ...payload
});

const fetchSparesDashBoardListFailure = () => ({
  type: FETCH_SPARES_LIST_FAILURE
});

// Excel spares list
const fetchExcelSparesDashBoardList = (payload) => ({
  type: FETCH_EXCEL_SPARES_LIST, ...payload
});

const fetchExcelSparesDashBoardListSuccess = (payload) => ({
  type: FETCH_EXCEL_SPARES_LIST_SUCCESS,
  ...payload
});

const fetchExcelSparesDashBoardListFailure = () => ({
  type: FETCH_EXCEL_SPARES_LIST_FAILURE
});

//
const fetchsparesEuipTypeList = (payload) => ({
  type: FETCH_SPARE_EQUIPTYPE_LIST,
  ...payload
});

const fetchsparesEuipTypeListSuccess = (payload) => ({
  type: FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS,
  ...payload
});

const fetchsparesEuipTypeListFailure = () => ({
  type: FETCH_SPARE_EQUIPTYPE_LIST_FAILURE
});

//
const fetchsparesEuipSNList = (payload) => ({
  type: FETCH_SPARE_EQUIPSN_LIST,
  ...payload
});

const fetchsparesEuipSNListSuccess = (payload) => ({
  type: FETCH_SPARE_EQUIPSN_LIST_SUCCESS,
  ...payload
});

const fetchsparesEuipSNListFailure = () => ({
  type: FETCH_SPARE_EQUIPSN_LIST_FAILURE
});

//
const fetchEuipSNList = (payload) => ({
  type: FETCH_EQUIPSN_LIST,
  ...payload
});

const fetchEuipSNListSuccess = (payload) => ({
  type: FETCH_EQUIPSN_LIST_SUCCESS,
  ...payload
});

const fetchEuipSNListFailure = () => ({
  type: FETCH_EQUIPSN_LIST_FAILURE
});

//

const fetchSapKitNoList = (payload) => ({
  type: FETCH_SPARE_SAPKITN_LIST,
  ...payload
});

const fetchSapKitNoListListSuccess = (payload) => ({
  type: FETCH_SPARE_SAPKITN_LIST_SUCCESS,
  ...payload
});

const fetchSapKitNoListListFailure = () => ({
  type: FETCH_SPARE_SAPKITN_LIST_FAILURE
});

//

const fetchSapNoList = (payload) => ({
  type: FETCH_SPARE_SAPNO_LIST,
  ...payload
});

const fetchSapNoListListSuccess = (payload) => ({
  type: FETCH_SPARE_SAPNO_LIST_SUCCESS,
  ...payload
});

const fetchSapNoListListFailure = () => ({
  type: FETCH_SPARE_SAPNO_LIST_FAILURE
});

//Add Individual spare Kit
const addIndvSpare = (payload) => ({
  type: ADD_SPARE_INDV,
  ...payload
});

const addIndvSpareFailure = () => ({
  type: ADD_SPARE_INDV_FAILURE
});

const addIndvSpareSuccess = (payload) => ({
  type: ADD_SPARE_INDV_SUCCESS,
  ...payload
});

//Add spare Kit
const addSpareKit = (payload) => ({
  type: ADD_SPARE_KIT,
  ...payload
});

const addSpareKitFailure = () => ({
  type: ADD_SPARE_KIT_FAILURE
});

const addSpareKitSuccess = (payload) => ({
  type: ADD_SPARE_KIT_SUCCESS,
  ...payload
});



const deleteDashboardSpare = (payload) => ({
  type: DELETE_DASHBOARD_SPARE,
  ...payload
});

const updateDashboardSpare = (payload) => ({
  type: UPDATE_DASHBOARD_SPARE,
  ...payload
});

const publishDashboardList = (payload) => ({
  type: PUBLISH_DASHBOARD_LIST,
  ...payload
});

export {
  fetchSparesDashBoardList,
  fetchSparesDashBoardListSuccess,
  fetchSparesDashBoardListFailure,
  fetchExcelSparesDashBoardList,
  fetchExcelSparesDashBoardListSuccess,
  fetchExcelSparesDashBoardListFailure,
  fetchsparesEuipTypeList,
  fetchsparesEuipTypeListSuccess,
  fetchsparesEuipTypeListFailure,
  fetchsparesEuipSNList,
  fetchsparesEuipSNListSuccess,
  fetchsparesEuipSNListFailure,
  fetchSapKitNoList,
  fetchSapKitNoListListSuccess,
  fetchSapKitNoListListFailure,
  fetchSapNoList,
  fetchSapNoListListSuccess,
  fetchSapNoListListFailure,
  addSpareKit,
  addSpareKitSuccess,
  addSpareKitFailure,
  addIndvSpare,
  addIndvSpareSuccess,
  addIndvSpareFailure,
  updateDashboardSpare,
  deleteDashboardSpare,
  publishDashboardList,
  fetchEuipSNList,
  fetchEuipSNListSuccess,
  fetchEuipSNListFailure
}