export const FETCH_SPARES_LIST = '@@admin/fetch-spares-list';
export const FETCH_SPARES_LIST_SUCCESS = '@@admin/fetch-spares-list-success';
export const FETCH_SPARES_LIST_FAILURE = '@@admin/fetch-spares-list-failure';

//
export const FETCH_SPARE_EQUIPTYPE_LIST = '@@admin/fetch-spare-equip-type-list';
export const FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS = '@@admin/fetch-spare-equip-type-list-success';
export const FETCH_SPARE_EQUIPTYPE_LIST_FAILURE = '@@admin/fetch-spare-equip-type-list-failure';

//
export const FETCH_SPARE_EQUIPSN_LIST = '@@admin/fetch-spare-equip-serialNo-list';
export const FETCH_SPARE_EQUIPSN_LIST_SUCCESS = '@@admin/fetch-spare-equip-serialNo-list-success';
export const FETCH_SPARE_EQUIPSN_LIST_FAILURE = '@@admin/fetch-spare-equip-serialNo-list-failure';

//
export const FETCH_SPARE_SAPKITN_LIST = '@@admin/fetch-sapKitNo-list';
export const FETCH_SPARE_SAPKITN_LIST_SUCCESS = '@@admin/fetch-sapKitNo-list-success';
export const FETCH_SPARE_SAPKITN_LIST_FAILURE = '@@admin/fetch-sapKitNo-list-failure';

//
export const FETCH_SPARE_SAPNO_LIST = '@@admin/fetch-sapNo-list';
export const FETCH_SPARE_SAPNO_LIST_SUCCESS = '@@admin/fetch-sapNo-list-success';
export const FETCH_SPARE_SAPNO_LIST_FAILURE = '@@admin/fetch-sapNo-list-failure';

//
export const ADD_SPARE = '@@admin/add-spare';
export const ADD_SPARE_SUCCESS = '@@admin/add-spare-success';
export const ADD_SPARE_FAILURE = '@@admin/add-spare-failure';

//
export const ADD_SAP_KIT_NUMBER = '@@admin/add-sap-kit-number';
export const ADD_SAP_KIT_NUMBER_SUCCESS = '@@admin/add-sap-kit-number-success';
export const ADD_SAP_KIT_NUMBER_FAILURE = '@@admin/add-sap-kit-number-failure';

//
export const UPDATE_ADMIN_SPARE = '@@admin/update-admin-spare';
export const UPDATE_ADMIN_SPARE_SUCCESS = '@@admin/update-admin-spare-success';
export const UPDATE_ADMIN_SPARE_FAILURE = '@@admin/update-admin-spare-failure';

//
export const FETCH_ADMIN_EXCEL_SPARES_LIST = '@@admin/fetch-excel-spares-list';
export const FETCH_ADMIN_EXCEL_SPARES_LIST_SUCCESS = '@@admin/fetch-excel-spares-list-success';
export const FETCH_ADMIN_EXCEL_SPARES_LIST_FAILURE = '@@admin/fetch-excel-spares-list-failure';

//
export const DELETE_ADMIN_SPARE = '@@admin/delete-admin-spare';
