import get from 'lodash/get';
import {
    FETCH_SPARES_LIST, FETCH_SPARES_LIST_SUCCESS, FETCH_SPARES_LIST_FAILURE, FETCH_SPARE_EQUIPTYPE_LIST, FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS, FETCH_SPARE_EQUIPTYPE_LIST_FAILURE, FETCH_SPARE_EQUIPSN_LIST, FETCH_SPARE_EQUIPSN_LIST_SUCCESS, FETCH_SPARE_EQUIPSN_LIST_FAILURE, ADD_SPARE, ADD_SPARE_SUCCESS, ADD_SPARE_FAILURE, FETCH_SPARE_SAPKITN_LIST, FETCH_SPARE_SAPKITN_LIST_SUCCESS, FETCH_SPARE_SAPKITN_LIST_FAILURE, FETCH_SPARE_SAPNO_LIST, FETCH_SPARE_SAPNO_LIST_SUCCESS, FETCH_SPARE_SAPNO_LIST_FAILURE, UPDATE_ADMIN_SPARE, DELETE_ADMIN_SPARE, ADD_SAP_KIT_NUMBER, ADD_SAP_KIT_NUMBER_SUCCESS, ADD_SAP_KIT_NUMBER_FAILURE, FETCH_ADMIN_EXCEL_SPARES_LIST, FETCH_ADMIN_EXCEL_SPARES_LIST_SUCCESS, FETCH_ADMIN_EXCEL_SPARES_LIST_FAILURE
} from './types';

const initialState = {
    sparesAdminList: [],
    sparesEuipTypeData: [],
    sparesEuipSnData: [],
    sparesSapNoData: [],
    sparesSapKitNoData: [],
    ExcelSparesAdminList: [],
    isFetchsparesAdminDataLoading: false,
    isSparesEuipTypeDataLoading: false,
    isSparesEuipSnDataLoading: false,
    isSparesSapKitNoDataLoading: false,
    isSparesSapNoDataLoading: false,
    isExcelSparesAdminDataLoading: false,
};

const spareAdminReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_SPARES_LIST: {
            return {
                ...state,
                isFetchsparesAdminDataLoading: true
            }
        }

        case FETCH_SPARES_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            resData.rows.sort(function (a, b) {
                return b.id - a.id;
            });
            resData.rows.map((item, i) => {
                item.sno = i + 1
            })
            return {
                ...state,
                sparesAdminList: resData.rows,
                sparesDataCount: resData.totalCount,
                isFetchsparesAdminDataLoading: false
            }
        }

        case FETCH_SPARES_LIST_FAILURE: {
            return {
                ...state,
                isFetchsparesAdminDataLoading: false
            }
        }

        //

        case FETCH_SPARE_EQUIPTYPE_LIST: {
            return {
                ...state,
                isSparesEuipTypeDataLoading: true
            }
        }

        case FETCH_SPARE_EQUIPTYPE_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesEuipTypeData: resData,
                isSparesEuipTypeDataLoading: false
            }
        }

        case FETCH_SPARE_EQUIPTYPE_LIST_FAILURE: {
            return {
                ...state,
                isSparesEuipTypeDataLoading: false
            }
        }

        //

        case FETCH_SPARE_EQUIPSN_LIST: {
            return {
                ...state,
                isSparesEuipSnDataLoading: true
            }
        }

        case FETCH_SPARE_EQUIPSN_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesEuipSnData: resData,
                isSparesEuipSnDataLoading: false
            }
        }

        case FETCH_SPARE_EQUIPSN_LIST_FAILURE: {
            return {
                ...state,
                isSparesEuipSnDataLoading: false
            }
        }

        //

        case FETCH_SPARE_SAPKITN_LIST: {
            return {
                ...state,
                isSparesSapKitNoDataLoading: true
            }
        }

        case FETCH_SPARE_SAPKITN_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesSapKitNoData: resData,
                isSparesSapKitNoDataLoading: false
            }
        }

        case FETCH_SPARE_SAPKITN_LIST_FAILURE: {
            return {
                ...state,
                isSparesSapKitNoDataLoading: false
            }
        }

        //

        case FETCH_SPARE_SAPNO_LIST: {
            return {
                ...state,
                isSparesSapNoDataLoading: true
            }
        }

        case FETCH_SPARE_SAPNO_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                sparesSapNoData: resData,
                isSparesSapNoDataLoading: false
            }
        }

        case FETCH_SPARE_SAPNO_LIST_FAILURE: {
            return {
                ...state,
                isSparesSapNoDataLoading: false
            }
        }

        case FETCH_ADMIN_EXCEL_SPARES_LIST: {
            return {
                ...state,
                isExcelSparesAdminDataLoading: true
            }
        }

        case FETCH_ADMIN_EXCEL_SPARES_LIST_SUCCESS: {
            const resData = get(action, 'response', []);
            return {
                ...state,
                ExcelSparesAdminList: resData,
                isExcelSparesAdminDataLoading: false
            }
        }

        case FETCH_ADMIN_EXCEL_SPARES_LIST_FAILURE: {
            return {
                ...state,
                isExcelSparesAdminDataLoading: false
            }
        }

        //

        case ADD_SPARE: {
            return {
                ...state,
                isFetchsparesAdminDataLoading: true
            }
        }

        case ADD_SPARE_SUCCESS: {
            return {
                ...state,
                isFetchsparesAdminDataLoading: false
            }
        }

        case ADD_SPARE_FAILURE: {
            return {
                ...state,
                isFetchsparesAdminDataLoading: false
            }
        }

        //

        case ADD_SAP_KIT_NUMBER: {
            return {
                ...state,
                isSparesSapKitNoDataLoading: true
            }
        }

        case ADD_SAP_KIT_NUMBER_SUCCESS: {
            return {
                ...state,
                isSparesSapKitNoDataLoading: false
            }
        }

        case ADD_SAP_KIT_NUMBER_FAILURE: {
            return {
                ...state,
                isSparesSapKitNoDataLoading: false
            }
        }

        //

        case DELETE_ADMIN_SPARE: {
            return {
                ...state,
                isFetchsparesAdminDataLoading: true
            }
        }

        //

        case UPDATE_ADMIN_SPARE: {
            return {
                ...state,
                isFetchsparesAdminDataLoading: true
            }
        }

        default: {
            return state;
        }
    }
};

export default spareAdminReducer;