/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  Row, Table, Col, Typography, Modal, Form,
  notification, Select, Tooltip, InputNumber, Button, Checkbox, Skeleton, Input, Radio
} from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  EyeOutlined
} from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import GenerateEquipmentReport from './GenerateEquipmentReport';
import get from 'lodash/get';
import EquipmentLogs from './EquipmentLogs';
import { connect } from 'react-redux';
import { addEquipDetails, deleteEquipDetails, fetchEquipCategory, fetchEquipDetails, fetchEquipMaintenanceIntervalDetails, getChecksDetails, getChecksLogDetails, updateEquipDetails } from '../redux/pihEquipmentDetails/action';
import moment from 'moment';
import { fetchMaintenanceTypes } from '../redux/maintenance/action';
import { fetchMaterialList } from '../redux/materialDescription/action';
import { fetchAdminEquipTypes } from '../redux/admin/equipmentTypes/action';
import { fetchAdminMaintenanceTypes, fetchAdminMaintenanceTypesByID } from '../redux/admin/maintenance/action';
import { fetchAdminEquipSno } from '../redux/admin/equipmentSno/action';
import CreateDmob from './createDmob';

const { Title, Text } = Typography;
const { Option } = Select;

const Equipment = (props) => {
  const { selectedProject,
    equipmentList,
    onAddEquipData,
    isEquipDetailsLoading,
    onFetchEquipDetails,
    isGetEquipDetailsLoading,
    isEquipUpdateLoading,
    equipDetails,
    equipDataCount,
    onUpdateEquipData,
    isEquipDeleteLoading,
    onDeleteEquipDetails,
    isChecksLoading,
    checksDetails,
    ongetChecksData,
    onGetMaintenanceTypes,
    maintenanceTypes,
    onGetMaterialList,
    materialList,
  } = props;

  const {
    onGetAdminEquipmentTypes,
    adminEquipTypes,
    onGetAdminMaintenanceTypes,
    onGetAdminMaintenanceTypesById,
    adminMaintenanceTypes,
    onGetEquipSno,
    adminEquipSnoData,
    adminEquipSnoLoading,
    adminMaintenanceTypesById,
    isAdminMaintenanceLoading,
    onGetCheckIntervals,
    // intervalsList,
    // isIntervalsLoading,
    onGetEquipMaintenanceInterval,
    equipMaintenanceIntervalData,
    equipMaintenanceIntervalLoading,
    checksDetailsLog,
    onGetChecksLogs,
    onGetCategory,
    isCategoryLoading,
    categoryData,
    adminEquipTypesLoading
  } = props;

  const [projectIdValue, setProjectIdValue] = useState(false);
  const [equipmentFormData] = Form.useForm();

  const [isEquipmentModalVisible, setIsEquipmentModalVisible] = useState(false);
  const [pihEquipment, setPihEquipment] = useState('');
  const [planned, setPlanned] = useState('');
  const [backup, setBackup] = useState('');
  const [editValue, setEditValue] = useState(false);
  const [newEquipment, setNewEquipment] = useState('');
  const [isEquipmentReportModalVisible, setIsEquipmentReportModalVisible] = useState(false)
  const [isTableModelVisible, setIsTableModelVisible] = useState(false)
  const [disableTypes, setDisableTypes] = useState(false);
  const [serialNo, setSerialNo] = useState('');
  const [serialNoList, setSerialNoList] = useState([]);

  const [equipmentData, setEquipmentData] = useState([]);

  const [dailyActive, setDailyActive] = useState('active');
  const [weeklyActive, setWeeklyActive] = useState('');
  const [monthlyActive, setMonthlyActive] = useState('');

  const [activeKey, setActiveKey] = useState('daily');


  const [equipTitle, setEquipTitle] = useState('');
  const [equipId, setEquipId] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState('');

  const [equipmentLogs, setEquipmentLogs] = useState('');
  const [calenderView, setCalenderView] = useState('');
  const [checksData, setChecksData] = useState([]);
  const [checksDataLog, setChecksDataLog] = useState([]);
  const [startDateInput, setStartDateInput] = useState('');
  const [endDateInput, setEndDateInput] = useState('');
  const [selectedDateInput, setSelectedDateInput] = useState(null);

  const [selectedMaintenanceType, setSelectedMaintenanceType] = useState([]);

  //setting admin equipment details
  const [adminEquipmentTypes, setAdminEquipmentTypes] = useState([]);
  const [adminMaintenanceTypesList, setAdminMaintenanceTypesList] = useState([]);


  const [adminEquipTypeId, setAdminEquipTypeId] = useState();
  const [adminEquipSnoId, setAdminEquipSnoId] = useState();
  const [clickedTableData, setClickedTableData] = useState(false);

  const [pihCategory, setPihCategory] = useState('');
  const [historyCurrentPage, setHistoryCurrentPage] = useState(1);


  const [searchKeyExist, setSearchKeyExist] = useState('');
  const [assignSpares, setAssignSpares] = useState(true);




  /** Setting coloumn (headers) for Equipment table */
  const columns = [
    {
      // title: 'PIH Equipment',
      dataIndex: 'sno',
      key: 'sno',
      align: 'left',
      width: '100px',
    },
    {
      title: 'PIH Category',
      dataIndex: 'pihEquipmentTypeCategory',
      key: 'pihEquipmentTypeCategory',
      // align: 'left',
      ellipsis: {
        showTitle: false
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      )
    },
    {
      title: 'PIH Equipment',
      dataIndex: 'pihEquipmentType',
      key: 'pihEquipmentType',
      // align: 'left',
      ellipsis: {
        showTitle: false
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      )
    },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      align: 'center',
      ellipsis: {
        showTitle: false
      },
      render: (address) => (
        <Tooltip placement="topLeft" title={address}>
          {address}
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      key: 'action',
      dataIndex: 'action',
      // fixed: 'right',
      width: '100px',
      render: (text, record) => {
        return (
          <div>
            <Tooltip title={(record.usageStatus === 1 ? "In Use" : (record.usageStatus === 2 ? 'In Bakup' : ''))} >
              <span className="icon-18 mr-2 cursor-pointer edit-hover"  >
                <span className='blinkingDot' style={{ background: (record.usageStatus === 1 ? 'limegreen' : (record.usageStatus === 2 ? 'orange' : 'none')) }}>
                  <span className='blinking' style={{ background: (record.usageStatus === 1 ? 'limegreen' : (record.usageStatus === 2 ? 'orange' : 'none')) }}></span>
                </span>
              </span>
            </Tooltip>
          </div>
        );
      },
      align: 'center'
    },
    {
      title: 'Actions',
      key: 'action',
      dataIndex: 'action',
      // fixed: 'right',
      width: '200px',
      render: (text, record) => {
        return (
          <div>
            <Tooltip title="View Checks" >
              <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginRight: '20px', }} >
                <EyeOutlined style={{ fontSize: '18px' }}
                  onClick={() => { handleEyeClick(record) }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Update">
              <span className="icon-18 mr-2 cursor-pointer edit-hover"
              // style={{ cursor: 'not-allowed' }}
              >
                <EditOutlined style={{ fontSize: '18px' }}
                  onClick={() => { showEditModal(record) }}
                />
              </span>
            </Tooltip>
            <Tooltip title="Delete">
              <span className="icon-18 mr-2 cursor-pointer edit-hover" style={{ marginLeft: '20px' }}>
                <DeleteOutlined style={{ fontSize: '18px' }} onClick={() => { showDeleteModal(record) }} />
              </span>
            </Tooltip>
          </div>
        );
      },
      align: 'center'
    },
  ];

  const [adminCompare, setAdminCompare] = useState([]);
  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(true);
  const [selectedEquipment, setSelectedEquipment] = useState(true);
  const [typesLoading, setTypesLoading] = useState(false);
  const [configuredMaintenanceTypes, setConfiguredMaintenanceTypes] = useState([]);

  const [equipmentLogsForm] = Form.useForm();

  const [intervalListData, setIntervalListData] = useState([]);
  const [pihMaintenanceType, setPihMaintenanceType] = useState('');
  const [intervalIdCheck, setIntervalIdCheck] = useState('');
  const [maintenanceIdCheck, setMaintenanceIdCheck] = useState('');
  const [current, setCurrent] = useState(1);
  const [loadUpdate, setLoadUpdate] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [categoryDataList, setCategoryDataList] = useState([]);


  /** Fetching API to get  Equipment details */
  useEffect(() => {
    const projId = get(selectedProject, 'id', undefined);
    if (projId) {
      setCurrent(1)
      var obj = {
        projectId: projId,
        pageSize: 10,
        pageNumber: 1

      }
      onFetchEquipDetails(obj);
      // onGetMaintenanceTypes();
      // onGetMaterialList();
      setStartDateInput(moment(selectedProject.startDate));
      setEndDateInput(moment(selectedProject.endDate));

      //Get Admin Types to add Project Equipments
      // onGetAdminEquipmentTypes();
      onGetCategory();
      // onGetAdminMaintenanceTypes();
    }
    setProjectIdValue(projId);
    setSearchKeyExist('');
  }, [selectedProject]);

  /** Getting details on load */
  useEffect(() => {
    //to make array unique using key
    equipDetails.map(item => item.key = item.serialNumber);
    setEquipmentData(equipDetails);
  }, [equipDetails]);

  /** Getting admin Equipment details on load */
  // useEffect(() => {
  //   setAdminEquipmentTypes(adminEquipTypes);

  //   //with no category on edit click function - New Enhancement for Category
  //   if (adminEquipTypes && adminEquipTypes.length > 0 && editValue) {
  //     const equipmentTypeId = adminEquipTypes.find(u => u.name === equipId.name);
  //     onGetAdminMaintenanceTypesById({ id: equipmentTypeId.id });
  //     onGetEquipMaintenanceInterval(equipId.id);

  //     setTimeout(() => {
  //       setLoadUpdate(false);
  //     }, 500);
  //   }
  // }, [adminEquipTypes]);


  /**Setting loading functionality for maintenance types  */
  useEffect(() => {
    if (isAdminMaintenanceLoading) {
      setTypesLoading(true);
      // setLoadUpdate(true);
    } else {
      setTypesLoading(false);
      setLoadUpdate(false);
    }
  }, [isAdminMaintenanceLoading]);

  /**Setting loading functionality for maintenance types (specific pih equipment) */
  useEffect(() => {
    if (equipMaintenanceIntervalLoading) {
      setTypesLoading(true);
    } else {
      setTypesLoading(false);
    }
  }, [equipMaintenanceIntervalLoading])

  /**Setting skeleton loading functionality on click of update to set all the values */
  // useEffect(() => {
  //   if (equipMaintenanceIntervalLoading && isAdminMaintenanceLoading) {
  //     setLoadUpdate(true);
  //     setTypesLoading(true);
  //   } else {
  //     setLoadUpdate(false);
  //     setTypesLoading(false);
  //   }
  // }, [equipMaintenanceIntervalLoading], [isAdminMaintenanceLoading]);


  /** Updating values while clicking update  */
  const showEditModal = async (record) => {
    let editData = null;
    if (record != null) {
      editData = {
        pihEquipment: record.pihEquipmentType,
        // pihEquipment: record.name,
        // planned: record.plannedNum,
        // backup: record.backupNum,
        serialNo: record.serialNumber,
        // pihCategory: record.pihEquipmentType
        pihCategory: record.pihEquipmentTypeCategory
      };
      setEquipId(record);
      setLoadUpdate(true);

      // - New Enhancement for Category
      // await onGetAdminEquipmentTypes({ pihEquipmentType: record.pihEquipmentType });
      // console.log(record.pihEquipmentType);

      //with no category - previous Api call without category

      // const equipmentTypeId = adminEquipmentTypes.find(u => u.name === record.name);
      // await onGetAdminMaintenanceTypesById({ id: equipmentTypeId.id });
      // await onGetEquipMaintenanceInterval(record.id);

      // const equipmentTypeId = categoryData.find(u => u.pihEquipmentType === record.pihEquipmentType);
      await onGetAdminMaintenanceTypesById({ id: record.equipmentId });
      await onGetEquipMaintenanceInterval(record.id);
    }
    equipmentFormData.setFieldsValue(editData);
    setEquipTitle('Update Equipment Data');
    setEditValue(true);
    setIsEquipmentModalVisible(true);
    setDisableTypes(true);
    setSelectedEquipment(true);

  };

  /** Display Confirmation while deleting */
  const showDeleteModal = (record) => {
    setIsDeleteModalVisible(true);
    setSelectedData(record)
  };

  /** Deleting Equipment data on confirm */
  const handleDeleteOk = async () => {
    selectedData.projectId = selectedProject.id;
    await onDeleteEquipDetails({ data: selectedData });
    setSearchKeyExist('');

  }

  /** Cancelling the deletion of euqipment data */
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedData('');
  }

  /** Close modal & delete values after confirmation equipment details */
  useEffect(() => {
    if (!isEquipDeleteLoading) {
      setIsDeleteModalVisible(false);
      setSelectedData('');
    }
  }, [isEquipDeleteLoading]);

  /** Show modal to add new equipment details */
  const showModal = () => {
    if (selectedProject && selectedProject.id) {
      setIsEquipmentModalVisible(true);
      setDisableTypes(false);
      setEquipTitle('Add New Equipment Data');
      setSerialNoList([]);
      equipmentFormData.resetFields();
      setPihEquipment('');
      //for check box
      setCheckedList(adminMaintenanceTypesList);
      setCheckAll(true);
      setIndeterminate(false);
      setSelectedEquipment(false);
      setAdminEquipmentTypes([]);
      equipmentFormData.setFieldsValue({ assignSpares: true, quantity: 1 });
      setAssignSpares(true);
    } else {
      notification.warning({
        message: 'Please select Project to Add Equipment'
      });
    }
  }

  /** Cloase modal on cancel */
  const handleCancel = () => {
    setIsEquipmentModalVisible(false);
    equipmentFormData.resetFields();
    setEditValue(false);
    setDisableTypes(false);
    setSerialNoList([]);
    // equipmentFormData.resetFields();
    setPihEquipment('');
    //for check box
    setCheckedList(adminMaintenanceTypesList);
    setCheckAll(true);
    setIndeterminate(false);
    setSelectedEquipment(false);
    setPihCategory('');
    setLoadUpdate(false)
  }

  /** Function to Add or Update equipment details */
  const handleOk = () => {
    equipmentFormData.validateFields().then(async (data) => {
      if (data.pihEquipment && data.serialNo) {
        if (!editValue) {
          // Check condition for assignSpares and quantity
          if (data.assignSpares && (!data.quantity || data.quantity <= 0)) {
            notification.warning({
              message: 'Please add quantity to proceed!'
            });
            return; // Exit the function without making the API call
          }
  
          // Construct sparePartOptions based on assignSpares and quantity
          let sparesObj = {
            assignSpares: Boolean(data.assignSpares),
            quantity: data.assignSpares ? Number(data.quantity) : 0
          };
  
          var obj = {
            projectId: selectedProject.id,
            equipmentId: adminEquipSnoId,
            sparePartOptions: sparesObj
          };
  
          var listArray = [];
          if (checkAll && checkedList.length > 0) {
            // All Maintenance Types Selected
            adminMaintenanceTypesById.forEach((item) => {
              listArray.push(item.id);
            });
  
            obj.configuredMaintenanceTypes = listArray;
  
            await onAddEquipData(obj);
          } else if (checkedList.length > 0) {
            // Specific Maintenance Types Selected
            for (let i = 0; i < adminMaintenanceTypesById.length; i++) {
              for (let j = 0; j < checkedList.length; j++) {
                if (adminMaintenanceTypesById[i].name === checkedList[j]) {
                  listArray.push(adminMaintenanceTypesById[i].id);
                }
              }
            }
            obj.configuredMaintenanceTypes = listArray;
  
            await onAddEquipData(obj);
          } else {
            notification.warning({
              message: 'Select at least one Maintenance Type to proceed!'
            });
          }
        } else {
          // Update PIH Equipment
          if (equipId) {
            var obj = {
              pihEquipment: {
                usageStatus: equipId.usageStatus
              }
            };
            var listArray = [];
            var searchKey = searchKeyExist || '';
  
            if (checkAll && checkedList.length > 0) {
              // All Maintenance Types Selected
              adminMaintenanceTypesById.forEach((item) => {
                listArray.push(item.id);
              });
  
              obj.pihEquipment.configuredMaintenanceTypes = listArray;
  
              await onUpdateEquipData({ data: obj, id: equipId.id, projectId: selectedProject.id, searchKey });
            } else if (checkedList.length > 0) {
              // Specific Maintenance Types Selected
              for (let i = 0; i < adminMaintenanceTypesById.length; i++) {
                for (let j = 0; j < checkedList.length; j++) {
                  if (adminMaintenanceTypesById[i].name === checkedList[j]) {
                    listArray.push(adminMaintenanceTypesById[i].id);
                  }
                }
              }
              obj.pihEquipment.configuredMaintenanceTypes = listArray;
  
              await onUpdateEquipData({ data: obj, id: equipId.id, projectId: selectedProject.id, searchKey });
            }
          } else {
            notification.warning({
              message: 'Unable to process your request. Close Window and try again!'
            });
          }
        }
      } else {
        notification.warning({
          message: 'Fill all the required fields!'
        });
      }
    });
  };
  

  /** Close modal & reset values after adding equipment details */
  useEffect(() => {
    if (!isEquipDetailsLoading) {
      equipmentFormData.resetFields();
      setIsEquipmentModalVisible(false);
      setEditValue(false);
      setSelectedEquipment(false);
    }
  }, [isEquipDetailsLoading]);

  /** Close modal & reset values after updating equipment details */
  useEffect(() => {
    if (!isEquipUpdateLoading) {
      equipmentFormData.resetFields();
      setIsEquipmentModalVisible(false);
      setEditValue(false);
      setEquipId(null);
    }
  }, [isEquipUpdateLoading]);

  /** Setting Serial Number list on equipment details */
  useEffect(() => {
    setSerialNoList(adminEquipSnoData);
  }, [adminEquipSnoData]);

  /** Remove duplicates from the PIH equipment Category */
  const RemoveDuplicates = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  /** Making PIH Equipment Category list unique by removing duplicates */
  useEffect(() => {
    if (categoryData && categoryData.length > 0) {
      var arr = RemoveDuplicates(categoryData, 'pihEquipmentTypeCategory');

      var filteredArr = arr.filter(function (el) {
        return el.pihEquipmentTypeCategory != null;
      });

      //make array of category in alphabetical order
      filteredArr.sort((a, b) => a.pihEquipmentTypeCategory.toLowerCase().localeCompare(b.pihEquipmentTypeCategory.toLowerCase()))

      //making array Camel Case and string which are enclosed in brases () to UPPERCASE
      for (let i = 0; i < filteredArr.length; i++) {
        filteredArr[i].category = titleCase(filteredArr[i].pihEquipmentTypeCategory);
      }

      setCategoryDataList(filteredArr);
    } else {
      setCategoryDataList([]);
    }
  }, [categoryData]);

  /** Function to make category list in alphabetical order */
  const titleCase = (str) => {
    if (str !== null) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      var stringUpperLowerCase = splitStr.join(' ');

      //After joining the string making string to uppercase which are enclosed in brases ()
      const result = capitalCase(stringUpperLowerCase);

      return result;
    } else {
      return str
    }
  }

  /** Function to make category list in UPPERCASE which are enclosed in brases() */
  const capitalCase = (str) => {
    const result = str
      .split(' ')
      .map((s) =>
        s.startsWith('(') && s.endsWith(')')
          ? s.toUpperCase()
          : s.slice(0, 1).toUpperCase() + s.slice(1),
      )
      .join(' ');
    return result;
  }

  /** On change function to select Category */
  const onChangeCategory = async (value) => {
    equipmentFormData.setFieldsValue({ serialNo: '', pihEquipment: '' });
    setSerialNo('');
    setPihEquipment('');
    setAdminEquipmentTypes([]);
    console.log(value, categoryData);
    console.log(categoryDataList)
    if (value) {
      setPihCategory(value);
      var equipArr = categoryData && categoryData.filter((item) => item.pihEquipmentTypeCategory == value);


      //remove duplicates
      var arr = RemoveDuplicates(equipArr, 'pihEquipmentType');
      //make array of equipment in alphabetical order
      arr.sort((a, b) => a.pihEquipmentType.toLowerCase().localeCompare(b.pihEquipmentType.toLowerCase()))

      setAdminEquipmentTypes(arr);
      // await onGetAdminEquipmentTypes({ pihEquipmentType: value });
    } else {
      setSerialNoList([]);
      setPihEquipment('');
      setSerialNo('');
      setSelectedEquipment(false);
      setPihCategory('');
      setAdminEquipmentTypes([]);
    }
  }
  /** On change function to select Equipment */
  const onChangeEquipmentType = async (value) => {
    equipmentFormData.setFieldsValue({ serialNo: '' });
    setSerialNo('');
    if (value) {
      // const equipmentTypeId = adminEquipmentTypes.find(u => u.name === value);
      const equipmentTypeId = categoryData.find(u => u.pihEquipmentType === value);
      setAdminEquipTypeId(equipmentTypeId.id);
      setPihEquipment(value);
      // setSelectedEquipment(true);
      await onGetEquipSno({ pihEquipmentTypeCategory: pihCategory, pihEquipmentType: value });
      // await onGetAdminMaintenanceTypesById({ id: equipmentTypeId.id });
    } else {
      setSerialNoList([]);
      setPihEquipment('');
      setSerialNo('');
      setSelectedEquipment(false);
    }
  }

  /** On change function to select Equipment Sno */
  const onChangeEquipmentSno = async (value) => {
    if (value) {
      const equipmentSnoId = serialNoList.find(u => u.serialNumber === value);
      setAdminEquipSnoId(equipmentSnoId.id);
      setSerialNo(value);
      setSelectedEquipment(true);
      await onGetAdminMaintenanceTypesById({ id: equipmentSnoId.id });

    } else {
      setSerialNo('');
      setAdminEquipSnoId('');
      setSelectedEquipment(false);
    }
  }

  const onSearch = (value, id) => {
    console.log('search:', value, id);
  };

  const onAddNewEquipment = (event) => {
    setNewEquipment(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    if (!isEmpty(newEquipment) && !isEmpty(newEquipment.trim())) {
      const equipmentObj = {
        name: newEquipment
      }
      //   onAddClient(roleData);
      setNewEquipment('');

      //for local only
      var arr = [...equipmentList];
      arr.push(equipmentObj)
      setEquipmentList(arr);
    }
  };

  const removeItem = (e) => {
    e.preventDefault();
    setNewEquipment('');
  }

  /** Close Modal on close of table details */
  const handleCancelTable = () => {
    setIsTableModelVisible(false);
    setDailyActive('');
    setWeeklyActive('');
    setMonthlyActive('');
    setSelectedDateInput('');
    setActiveKey('');
    setChecksData([]);
    setChecksDataLog([])
    setClickedTableData(false);
    setHistoryCurrentPage(1);
  }

  /**Open checks table / Modal on view of checks (double click on row disabled)  */
  const handleEyeClick = async (record) => {
    setChecksData([]);
    setChecksDataLog([]);
    setIsTableModelVisible(true);
    setHistoryCurrentPage(1);
    // setCalenderView('day');
    // setDailyActive('active');
    setEquipmentLogs(record);
    var todaysDate = moment();

    // setSelectedDateInput(todaysDate);
    var obj = {
      pihEquipmentId: record.id,
      fromDate: moment(todaysDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      toDate: moment(todaysDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
      // checkIntervalId: '',
      // typeOfMaintenanceId: ''
    }
    // await ongetChecksData(obj);
    equipmentLogsForm.resetFields();
    await onGetEquipMaintenanceInterval(record.id);
    // await onGetCheckIntervals();
  }

  /** Setting maintenance types for specific pih equipments */
  useEffect(() => {
    var arr = [];
    if (equipMaintenanceIntervalData.length > 0) {
      //for pih equipment logs (history view of checks)
      setConfiguredMaintenanceTypes(equipMaintenanceIntervalData);
      //default select first maintenance type
      setPihMaintenanceType(equipMaintenanceIntervalData[0]);
      equipmentLogsForm.setFieldsValue({ pihMaintenanceType: equipMaintenanceIntervalData[0].name });
      setIntervalListData(equipMaintenanceIntervalData[0].check_intervals);

      var intervalTab = (equipMaintenanceIntervalData[0].check_intervals[0].name).toLowerCase();
      var dateInterval;

      //set maintenance and interval ID
      setIntervalIdCheck(equipMaintenanceIntervalData[0].check_intervals[0].id);
      setMaintenanceIdCheck(equipMaintenanceIntervalData[0].id);

      //for tab selection
      setActiveKey(intervalTab);
      //for calender view
      if (intervalTab === ('daily' || 'day')) {
        setCalenderView('day');
        dateInterval = 'day'
      } else if (intervalTab === ('weekly' || 'week')) {
        setCalenderView('week');
        dateInterval = 'week'
      } else if (intervalTab === ('monthly' || 'month')) {
        setCalenderView('month');
        dateInterval = 'month'
      } else if (intervalTab === ('quarterly' || 'quarter')) {
        setCalenderView('quarter');
        dateInterval = 'quarter'
      } else if (intervalTab === ('yearly' || 'year')) {
        setCalenderView('year');
        dateInterval = 'year'
      } else {
        setCalenderView('custom');
        dateInterval = 'day';
      }
      var todaysDate = moment();
      //for calender date input
      // setSelectedDateInput(null);
      // console.log(equipmentLogs, equipMaintenanceIntervalData[0].check_intervals[0], equipMaintenanceIntervalData[0])
      var obj = {
        pihEquipmentId: equipmentLogs.id,
        // fromDate: moment(todaysDate).startOf(dateInterval).format('YYYY-MM-DD HH:mm:ss'),
        // toDate: moment(todaysDate).endOf(dateInterval).format('YYYY-MM-DD HH:mm:ss'),
        checkIntervalId: equipMaintenanceIntervalData[0].check_intervals[0].id,
        typeOfMaintenanceId: equipMaintenanceIntervalData[0].id
      }
      //get checks details on view
      if (obj.pihEquipmentId && obj.checkIntervalId && obj.typeOfMaintenanceId) {
        // ongetChecksData(obj);
        onGetChecksLogs(obj);
      }

      // //for updating pih equipment view
      // for (let i = 0; i < equipMaintenanceIntervalData.length; i++) {
      //   arr.push(equipMaintenanceIntervalData[i].name)
      // }

      // //for Updating PIH Equipment
      // setCheckedList(arr);
      // setAdminMaintenanceTypesList(arr);
    }
  }, [equipMaintenanceIntervalData]);


  /** Setting Maintenance types while updating the pih equipment */
  useEffect(() => {
    var arrList = [];
    var arr = [];
    if (editValue) {
      // console.log("NEW changes");
      for (let i = 0; i < adminMaintenanceTypesById.length; i++) {
        arr.push(adminMaintenanceTypesById[i].name);
        for (let j = 0; j < equipMaintenanceIntervalData.length; j++) {

          if (adminMaintenanceTypesById[i].name === equipMaintenanceIntervalData[j].name) {
            arrList.push(adminMaintenanceTypesById[i].name);
          }

        }
      }
      // console.log(arrList, arr);
      setAdminCompare(arrList)
      setCheckedList(arrList)
      console.log("arr", arr)
      setAdminMaintenanceTypesList(arr);
      setIndeterminate(!!arrList.length && arrList.length < adminMaintenanceTypesById.length);
      setCheckAll(arrList.length === adminMaintenanceTypesById.length);
    }

  }, [adminMaintenanceTypesById], [equipMaintenanceIntervalData])

  /** Setting Admin Maintenance Types Data on Load */
  useEffect(() => {
    var arr = [];
    if (selectedEquipment && !editValue) {
      // console.log("OLD changes");
      if (adminMaintenanceTypesById.length > 0) {
        for (let i = 0; i < adminMaintenanceTypesById.length; i++) {
          arr.push(adminMaintenanceTypesById[i].name);
        }
        console.log("arr", arr)
        setCheckedList(arr)
        setAdminMaintenanceTypesList(arr);
      } else {
        setCheckedList([])
        setAdminMaintenanceTypesList([]);
      }
    }
  }, [adminMaintenanceTypesById]);


  /** Getting details on double click on table row */
  useEffect(() => {
    if (checksDetails && checksDetails.length > 0) {
      setChecksData(checksDetails);
    } else {
      setChecksData([]);
    }
  }, [checksDetails]);

  /** Getting details on double click on table row */
  useEffect(() => {
    if (checksDetailsLog && checksDetailsLog.length > 0) {
      setChecksDataLog(checksDetailsLog);
    } else {
      setChecksDataLog([]);
    }
  }, [checksDetailsLog]);

  const onChangeCheckBox = (list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < adminMaintenanceTypesList.length);
    setCheckAll(list.length === adminMaintenanceTypesList.length);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? adminMaintenanceTypesList : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const onSearchEquip = async (event) => {
    // console.log(event.target.value)
    if (event.target.value) {
      setSearchKeyExist(event.target.value);
      await onFetchEquipDetails({ projectId: projectIdValue, searchKey: event.target.value })
    } else {
      setCurrent(1);
      setSearchKeyExist('');
      var obj = {
        projectId: projectIdValue,
        pageSize: 10,
        pageNumber: 1
      }
      await onFetchEquipDetails(obj)
    }

  }
  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };


  return (
    <div className="h-100 overflow-y-hidden overflow-x-hidden equipmentsPage">
      <div className="flex-auto">
        <div className="d-flex mb-3 align-items-center tab-content-title">
          <Title className="mb-0" level={4}>
            List of Equipments
          </Title>
          <div className='searchEquip'>
            <Input
              placeholder='Search Equipments'
              onChange={onSearchEquip}
              value={searchKeyExist}
              allowClear
            />
          </div>
          {/* <div className='searchEquip'>
            <Input
              placeholder='Select Phases'
              onChange={onSearchEquip}
              value={searchKeyExist}
              allowClear
            />
          </div> */}
          <div className='inspection-logs'>
            <div className="new-project-team">
              <Text onClick={showModal} className="px-3 add-new-team" >
                <span className="icon-22">
                  <svg>
                    <use xlinkHref="#add" />
                  </svg>
                </span>
                Add New
              </Text>
            </div>
            {/* <GenerateEquipmentReport
              selectedProject={selectedProject}
              isEquipmentReportModalVisible={isEquipmentReportModalVisible}
              setIsEquipmentReportModalVisible={setIsEquipmentReportModalVisible}
              projectIdValue={projectIdValue}
            /> */}
          </div>
        </div>
        <div className="custom-line" />
      </div>
      <div className='addTeamDetails'>
        <Table

          columns={columns}
          dataSource={[...equipmentData]}
          className="h-100 pipeline-table equip_table equipPaginationTable"
          scroll={{ y: 'calc(100vh - 525px)' }}

          pagination={equipDataCount > 10 ? {
            position: ['bottomRight'],
            showSizeChanger: true,
            total: equipDataCount,
            defaultPageSize: 10,
            defaultCurrent: 1,
            current: current,

            onChange: async (pageNumber, pageSize) => {
              setCurrent(pageNumber);
              if (projectIdValue) {
                var obj = {
                  projectId: projectIdValue,
                  pageSize: pageSize,
                  pageNumber: pageNumber
                }
                // console.log(projId ,pageNumber, pageSize,equipDataCount);
                await onFetchEquipDetails(obj);
              }
            }
          } : false
          }
          // onRow={(record, rowIndex) => {
          //   return {
          //     onDoubleClick: event => {
          //       handleDoubleClick(record)
          //       // setIsTableModelVisible(true),
          //       // setCalenderView('day'), 
          //       // setDailyActive('active'), 
          //       // setEquipmentLogs(record), 
          //       // console.log('Row clicked', event, record, rowIndex) 
          //     }, // click row
          //     // onMouseEnter: event => {<Tooltip title='Double Click to Display Data' placement='top'>{event}</Tooltip>}, // mouse enter row
          //   };
          // }}
          loading={isGetEquipDetailsLoading}
        />
        <div className="new-team">

          {/* <Text className='equip_note'>Double Click on table row to display Equipment Logs.</Text> */}
        </div>
      </div>

      <Modal
        // getContainer={false}
        width="45vw"
        className="pipeline-modal"
        title={equipTitle}
        centered
        visible={isEquipmentModalVisible}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleOk} loading={isEquipDetailsLoading || isEquipUpdateLoading}>
            {!editValue ? (isEquipDetailsLoading ? 'Adding' : 'Add') : (isEquipUpdateLoading ? 'Updating' : 'Update')}
          </Button>
        ]}
      >
        <div>
          {loadUpdate ? <Skeleton active /> :
            <Form
              form={equipmentFormData}
              name="basic"
              layout="vertical"
              autoComplete="off"
              getcontainer={false}
            >
              <Row gutter={24}>
                <Col lg={12}>
                  <Form.Item
                    label="PIH Category"
                    name="pihCategory"
                    required
                  >
                    <Select
                      className='client-select'
                      placeholder='Select Category'
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      onChange={onChangeCategory}
                      disabled={disableTypes}
                    >
                      {categoryDataList && categoryDataList.map((item, i) =>
                        <Option key={i} value={item && item.pihEquipmentTypeCategory}>{item && item.category}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="PIH Equipment"
                    name="pihEquipment"
                    required
                  >
                    <Select
                      className='client-select'
                      placeholder='Select Equipment'
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      onChange={onChangeEquipmentType}
                      disabled={disableTypes}
                    >
                      {/* {adminEquipTypesLoading && <Option key={'loading'} value={'Loading...'} />} */}
                      {adminEquipmentTypes && adminEquipmentTypes.map((item, i) =>
                        <Option key={i} value={item && item.pihEquipmentType}>{item && item.pihEquipmentType}</Option>)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={12}>
                  <Form.Item
                    label="Serial No. / Location"
                    name="serialNo"
                    required
                  >
                    <Select
                      className='client-select'
                      placeholder={adminEquipSnoLoading ? 'Loading...' : 'Select Sl.No.'}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      onChange={onChangeEquipmentSno}
                      disabled={disableTypes || !pihEquipment}
                    >
                      {adminEquipSnoLoading && <Option key={'loading'} value={'Loading...'} />}
                      {!adminEquipSnoLoading && serialNoList && serialNoList.map((item, i) =>
                        <Option key={i} value={item && item.serialNumber}>{item && item.serialNumber}</Option>
                      )}
                    </Select>
                  </Form.Item>
                </Col>

                {/* <Col lg={12}>
                  <Form.Item
                    name="serialNo"
                    required
                  >

                  </Form.Item>
                </Col> */}
                {
                  !editValue && <Col lg={24}>
                    <Form.Item
                      label="Assign Spares"
                      name="assignSpares"
                      required
                    >
                      <Radio.Group onChange={e => setAssignSpares(e.target.value)} value={assignSpares}>
                        <Row>
                          <Col span={12}>
                            <Radio value={true}>Yes</Radio>
                          </Col>
                          <Col span={12}>
                            <Radio value={false}>No</Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                }

                {
                  !editValue && assignSpares && <Col lg={6}>
                    <Form.Item
                      label="Quantity"
                      name="quantity"
                      required
                    >
                      <Input
                        placeholder={'Quantity'}
                        value={quantity}
                        // defaultValue={1}
                        onChange={(e) => setQuantity(e.target.value)}
                        onKeyPress={handleKeyPress}
                        min={1}
                        type='number'
                        size="large" />
                    </Form.Item>
                  </Col>
                }
                {/* <Col lg={12}>
                <Form.Item
                  label="Planned"
                  name="planned"
                  required
                >
                  <InputNumber
                    type="number"
                    value={planned}
                    onChange={(e) => setPlanned(e)}
                    size="large" />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item
                  label="Back up"
                  name="backup"
                  required
                >
                  <InputNumber
                    type="number"
                    value={backup}
                    onChange={(e) => setBackup(e)}
                    size="large" />
                </Form.Item>
              </Col> */}
                <Col lg={24}>
                  <Form.Item
                    label="Maintenance Type"
                    // name="maintenanceType"
                    required
                  >
                    {/* <Select
                    className='maintenance-select'
                    placeholder='Select one or more Maintenance Types...'
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onChange={e => setMaintenanceType(e)}
                    mode="multiple"
                  >
                    {adminMaintenanceTypesList && adminMaintenanceTypesList.map((item, i) =>
                      <Option key={i} value={item && item.name}>{item && item.name}</Option>)
                    }
                  </Select> */}
                    {typesLoading ?
                      <Text>Loading...</Text> :
                      <>
                        {selectedEquipment ?
                          <>
                            {adminMaintenanceTypesList.length > 0 ?
                              <>
                                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}
                                  disabled={disableTypes}
                                >
                                  <Text> Select all</Text>
                                </Checkbox>
                                <hr />
                                <Checkbox.Group
                                  onChange={onChangeCheckBox}
                                  value={checkedList}
                                  style={{ width: '100%' }}
                                >
                                  <Row gutter={24}>
                                    {adminMaintenanceTypesList && adminMaintenanceTypesList.map((item, i) =>
                                      <Col span={12} key={i}>
                                        <Checkbox
                                          value={item}
                                          disabled={disableTypes && adminCompare.find(data => data === item)}
                                        >
                                          <Text>{item}</Text>
                                        </Checkbox>
                                      </Col>
                                    )}
                                  </Row>
                                </Checkbox.Group>
                              </> :
                              <Text>{(adminMaintenanceTypesList && adminMaintenanceTypesList.length === 0 && '---- No Data ----')}</Text>
                            }
                          </>
                          : <Text>Select Serial No. / Location to select Maintenance Types</Text>}
                      </>
                    }
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          }
        </div>
      </Modal >

      <Modal
        className="equipTable-modal"
        title={`Equipment Data - ${equipmentLogs.name} -  ${equipmentLogs.serialNumber}`}
        centered
        visible={isTableModelVisible}
        footer={[
          <Button key="cancel" onClick={handleCancelTable}>
            Close
          </Button>,

        ]}
      >
        <EquipmentLogs
          dailyActive={dailyActive}
          setDailyActive={setDailyActive}
          weeklyActive={weeklyActive}
          setWeeklyActive={setWeeklyActive}
          monthlyActive={monthlyActive}
          setMonthlyActive={setMonthlyActive}
          selectedProject={selectedProject}
          equipmentLogs={equipmentLogs}
          calenderView={calenderView}
          setCalenderView={setCalenderView}
          checksData={checksData}
          setChecksData={setChecksData}
          checksDataLog={checksDataLog}
          setChecksDataLog={setChecksDataLog}
          startDateInput={startDateInput}
          endDateInput={endDateInput}
          selectedDateInput={selectedDateInput}
          setSelectedDateInput={setSelectedDateInput}
          activeKey={activeKey}
          setActiveKey={setActiveKey}
          intervalListData={intervalListData}
          setIntervalListData={setIntervalListData}
          // isIntervalsLoading={isIntervalsLoading}
          configuredMaintenanceTypes={configuredMaintenanceTypes}
          equipMainIntLoading={equipMaintenanceIntervalLoading}
          setPihMaintenanceType={setPihMaintenanceType}
          pihMaintenanceType={pihMaintenanceType}
          equipmentLogsForm={equipmentLogsForm}
          intervalIdCheck={intervalIdCheck}
          setIntervalIdCheck={setIntervalIdCheck}
          setMaintenanceIdCheck={setMaintenanceIdCheck}
          maintenanceIdCheck={maintenanceIdCheck}
          setClickedTableData={setClickedTableData}
          clickedTableData={clickedTableData}
          historyCurrentPage={historyCurrentPage}
          setHistoryCurrentPage={setHistoryCurrentPage}
          handleCancelTable={handleCancelTable}
        />
      </Modal>

      <Modal
        width="45vw"
        className="pipeline-modal"
        title="Delete Equipment Details"
        centered
        visible={isDeleteModalVisible}
        footer={[
          <Button key="cancel" onClick={handleDeleteCancel}>
            Cancel
          </Button>,
          <Button key="apply" type="primary" onClick={handleDeleteOk} loading={isEquipDeleteLoading}>
            {isEquipDeleteLoading ? 'Deleting' : 'Delete'}
          </Button>
        ]}
      >
        <div>
          <Text style={{ fontSize: '16px' }}>
            <p>Deleting Equipment Details will delete all the corresponding checks and related information.</p>
            Are you sure you want to delete <b style={{ color: "#EF314C" }}>{selectedData && selectedData.name + " - " + selectedData.serialNumber}</b> equipment?</Text>
        </div>
      </Modal>
    </div >
  );
}

const mapStateToProps = ({
  equip,
  maintenanceReducer, materialReducer,
  adminEquipTypeReducer, maintenanceAdminReducer,
  adminEquipSnoReducer, checkIntervalReducer
}) => {
  const isEquipDetailsLoading = get(equip, 'isEquipDetailsLoading', false);
  const isGetEquipDetailsLoading = get(equip, 'isGetEquipDetailsLoading', false);
  const isEquipUpdateLoading = get(equip, 'isEquipUpdateLoading', false);
  const isEquipDeleteLoading = get(equip, 'isEquipDeleteLoading', false);
  const equipDetails = get(equip, 'equipData', []);
  const equipDataCount = get(equip, 'equipDataCount', null);
  const isChecksLoading = get(equip, 'isChecksLoading', false);
  const checksDetails = get(equip, 'checksData', []);
  const checksDetailsLog = get(equip, 'checksLogData', []);
  const maintenanceTypes = get(maintenanceReducer, 'maintenanceTypes', []);
  const materialList = get(materialReducer, 'materialList', []);
  const equipMaintenanceIntervalData = get(equip, 'equipMaintenanceIntervalData', []);
  const equipMaintenanceIntervalLoading = get(equip, 'equipMaintenanceIntervalLoading', false);

  const adminEquipTypes = get(adminEquipTypeReducer, 'adminEquipTypes', []);
  const adminEquipTypesLoading = get(adminEquipTypeReducer, 'adminEquipTypesLoading', false);
  const adminMaintenanceTypes = get(maintenanceAdminReducer, 'adminMaintenanceTypes', []);
  const adminMaintenanceTypesById = get(maintenanceAdminReducer, 'adminMaintenanceTypesById', []);
  const adminEquipSnoData = get(adminEquipSnoReducer, 'adminEquipSnoData', []);
  const adminEquipSnoLoading = get(adminEquipSnoReducer, 'adminEquipSnoLoading', false);
  const isAdminMaintenanceLoading = get(maintenanceAdminReducer, 'isAdminMaintenanceLoading', false);

  const categoryData = get(equip, 'categoryData', []);
  const isCategoryLoading = get(equip, 'isCategoryLoading', false);

  // const intervalsList = get(checkIntervalReducer, 'intervalsList', []);
  // const isIntervalsLoading = get(checkIntervalReducer, 'isIntervalsLoading', false);
  return {
    isEquipDetailsLoading,
    isGetEquipDetailsLoading,
    equipDetails,
    equipDataCount,
    isEquipUpdateLoading,
    isEquipDeleteLoading,
    isChecksLoading,
    checksDetails,
    maintenanceTypes,
    materialList,
    equipMaintenanceIntervalData,
    equipMaintenanceIntervalLoading,
    adminEquipTypesLoading,

    adminEquipTypes,
    adminMaintenanceTypes,
    adminEquipSnoData,
    adminEquipSnoLoading,
    adminMaintenanceTypesById,
    isAdminMaintenanceLoading,
    checksDetailsLog,

    isCategoryLoading,
    categoryData
    // intervalsList,
    // isIntervalsLoading,
  }
}

const mapDispatchToProps = {
  onAddEquipData: addEquipDetails,
  onFetchEquipDetails: fetchEquipDetails,
  onUpdateEquipData: updateEquipDetails,
  onDeleteEquipDetails: deleteEquipDetails,
  ongetChecksData: getChecksDetails,
  onGetMaintenanceTypes: fetchMaintenanceTypes,
  onGetMaterialList: fetchMaterialList,
  onGetEquipMaintenanceInterval: fetchEquipMaintenanceIntervalDetails,

  onGetAdminEquipmentTypes: fetchAdminEquipTypes,
  onGetAdminMaintenanceTypes: fetchAdminMaintenanceTypes,
  onGetAdminMaintenanceTypesById: fetchAdminMaintenanceTypesByID,
  onGetEquipSno: fetchAdminEquipSno,
  onGetChecksLogs: getChecksLogDetails,
  onGetCategory: fetchEquipCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(Equipment);