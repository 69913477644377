/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';
import { fetchEquipmentUsage } from '../../redux/equipmentUsage/action';
import { get } from 'lodash';

const EquipmentUsage = (props) => {
    const { equipmentUsage, isFetchEquipmentUsageLoading, onFetchEquipmentUsage, selectedData, selectedProject, dsrSowId } = props;

    console.log("selectedData", selectedData)

    useEffect(() => {
        const shouldCallOnFetchEquipmentUsage =
            !(selectedData.sectionsSkipped.isEquipmentUsageSkippedDayShift &&
                selectedData.sectionsSkipped.isEquipmentUsageSkippedNightShift);

        if (!isFetchEquipmentUsageLoading && shouldCallOnFetchEquipmentUsage) {
            let data = { selectedData: selectedData, selectedProject: selectedProject, sowId: dsrSowId }
            onFetchEquipmentUsage(data)
        }
    }, [selectedData, selectedProject]);

    const getStatus = (status) => {
        if (status == 1) {
            return "OFFLINE"
        } else if (status == 2) {
            return "BACKUP"
        } else if (status == 3) {
            return "ONLINE"
        } else if (status == 4) {
            return "Mobilize"
        } else if (status == 5) {
            return "DeMobilize"
        }
    }

    const getSpareAvailable = (spareAvailable) => {
        if (spareAvailable == 1) {
            return "YES"
        } else if (spareAvailable == 2) {
            return "NO"
        }
    }

    const columns = [
        {
            title: 'Description',
            dataIndex: 'pihEquipmentType',
            key: 'pihEquipmentType',
            align: 'left',
        },
        {
            title: 'Serial No.',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            align: 'left'
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'left',
            render: (value) => {
                return <span>{getStatus(value)}</span>;
            }
        },
        {
            title: 'Spare Avail. (Y/N)',
            dataIndex: 'spareAvailable',
            key: 'spareAvailable',
            align: 'left',
            render: (value) => {
                return <span>{getSpareAvailable(value)}</span>;
            }
        },
        {
            title: 'Issues',
            dataIndex: 'issues',
            key: 'issues',
            align: 'left',
            ellipsis: true
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            align: 'left',
            ellipsis: true
        },
        {
            title: 'HS Code',
            dataIndex: 'hsCode',
            key: 'hsCode',
            align: 'left'
        },
    ];

    return (
        <div className="h-100 overflow-y-hidden overflow-x-hidden">
            <div className='addTeamDetails'>
                {selectedData.sectionsSkipped.isEquipmentUsageSkippedDayShift && selectedData.sectionsSkipped.isEquipmentUsageSkippedNightShift ? (
                    <div className="dsr-skipped">Material Consumption Skipped</div>
                ) : (
                        <Table
                            pagination={false}
                            columns={columns}
                            dataSource={equipmentUsage}
                            className="h-100 pipeline-table "
                            scroll={{ y: 'calc(100vh - 596px)' }}
                            loading={isFetchEquipmentUsageLoading}
                        />
                )}
            </div>
        </div>
    );
};


const mapStateToProps = ({ equipmentUsageReducer }) => {
    const equipmentUsage = get(equipmentUsageReducer, 'equipmentUsage', [])
    const isFetchEquipmentUsageLoading = get(equipmentUsageReducer, 'isFetchEquipmentUsageLoading', false)

    return {
        equipmentUsage, isFetchEquipmentUsageLoading
    }
}

const mapDispatchToProps = {
    onFetchEquipmentUsage: fetchEquipmentUsage
}

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentUsage);